import { call, put } from "redux-saga/effects";
import axios from "axios";

import {
  setAccountVerification,
  setAccountotp,
  setLoaderCall,
  setTemporaryPassword,
  setTemporaryRes,
} from "../../StateManagement/Reducers/SubscriptionRenewalState";
import {
  AccountVerificationApi,
  GetRecoverDataApi,
  TemporaryResApi,
} from "../Apis/SubscriptionRenewalApi";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";
import axiosInstance from "../../axios/axios";

var user_id =
  localStorage.getItem("user_id") &&
  Decrypt_Value(localStorage.getItem("user_id"), "vozo");
export function* AccountVerification({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "vozo");
  try {
    const res = yield call(AccountVerificationApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");

      yield put(setAccountotp(Data.output));
      localStorage.setItem("AccountOTP", Encrypt_Value(Data.output, "vozo"));
      yield put(setAccountVerification(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}
export function* TemporaryRes({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "vozo");
  try {
    const res = yield call(TemporaryResApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");

      yield put(setTemporaryPassword(Data.output));
      localStorage.setItem(
        "TemporaryPassword",
        Encrypt_Value(Data.output, "vozo")
      );
      yield put(setTemporaryRes(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}
