import { takeLatest } from "redux-saga/effects";

import {
  GetClinicalReportDetails,
  GetClinicalReportCount,
  GetCurrency,
} from "../../StateManagement/Reducers/ReportState";

import {
  ClinicalReportCountApi,
  ClinicalReportDetailsApi,
  CurrencyApi,
} from "./ReportSagaWorkers";

export function* ClinicReportDetails() {
  yield takeLatest(GetClinicalReportDetails.type, ClinicalReportDetailsApi);
}

export function* ClinicReportCount() {
  yield takeLatest(GetClinicalReportCount.type, ClinicalReportCountApi);
}

export function* Currency() {
  yield takeLatest(GetCurrency.type, CurrencyApi);
}
