import React from "react";
import { useHistory } from "react-router-dom";
import {
  BillingBlock,
  Div,
  Image,
  Link,
  Text,
} from "../../../StyledComponents";
import account_reactive from "../../../assets/images/account_reactive.png";
import recover_data from "../../../assets/images/recover_data.png";
import { BsArrowLeft } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { setReactiveRecover } from "../../../StateManagement/Reducers/SubscriptionRenewalState";

const AccountManagement = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const handleReactivate = () => {
    dispatch(setReactiveRecover("reactive_acct"));
    history.push("/accountverify");
  };
  const handleRecover = () => {
    dispatch(setReactiveRecover("recover_data"));
    history.push("/accountverify");
  };

  return (
    <>
      <Div backColor="unset" width="100%" height="100%" flexCenterAll>
        <Div borderRadius="8px">
          <Div margin="35px 38px" flexCenterAll flexDirection="column">
            <Text fontWeight="600" fontFamily="Open Sans" fontSize="24px">
              Account Management
            </Text>
            <Text
              fontSize="16px"
              color="rgba(46, 46, 46, 0.75)"
              width="544px"
              textAlign="left"
              fontFamily="Open Sans"
            >
              Your account is currently in hibernated state, you can’t login and
              use your account. You can either reactivate your account for usage
              or recover your data for further use by downloading it.
            </Text>
            <Div flexCenterAll marginTop="40px" marginBottom="40px">
              <Div
                border="1px solid rgba(46, 46, 46, 0.25)"
                borderRadius="8px"
                marginRight="40px"
                cursor="pointer"
                onClick={handleReactivate}
              >
                <Div margin="28px 26px" flexDirection="column" flexCenterAll>
                  <Image src={account_reactive} width="120px" height="100px" />
                  <Text
                    fontWeight="600"
                    marginTop="24px"
                    fontFamily="Open Sans"
                  >
                    Reactivate your account
                  </Text>
                </Div>
              </Div>
              <Div
                border="1px solid rgba(46, 46, 46, 0.25)"
                borderRadius="8px"
                cursor="pointer"
                onClick={handleRecover}
              >
                <Div margin="28px 20px" flexDirection="column" flexCenterAll>
                  <Image src={recover_data} width="164px" height="100px" />
                  <Text
                    fontWeight="600"
                    marginTop="24px"
                    fontFamily="Open Sans"
                  >
                    Recover your data
                  </Text>
                </Div>
              </Div>
            </Div>
            <Link
              onClick={() => {
                localStorage.clear();
                localStorage.setItem("logout", true);
                window.location.href = "/";
              }}
            >
              <Div flexCenterAll color="#2C7BE5 !important" fontSize="16px">
                <BillingBlock
                  marginRight="8px"
                  marginLeft="unset"
                  color="#2C7BE5 !important"
                >
                  <BsArrowLeft />
                </BillingBlock>
                <BillingBlock color="#2C7BE5 !important" marginLeft="unset">
                  Back to Login
                </BillingBlock>
              </Div>
            </Link>
          </Div>
        </Div>
      </Div>
    </>
  );
};

export default AccountManagement;
