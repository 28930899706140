import React, { useState, useEffect } from "react";

const AudioPreview = ({ audioBlob }) => {
  const [reblob, setReblob] = useState([]);

  useEffect(() => {
    setReblob(audioBlob);
  }, [audioBlob]);

  return (
    <div>
      {reblob.length > 0 && (
        <audio
          className="aud-top-nav2"
          controls
          src={reblob && reblob[0]}
          //src={reblob && reblob}
          // style={{
          //   height: "36px",
          //   width: "400px",
          //   marginLeft: "8px",
          //   marginRight: "12px",
          //   marginBottom: "-10px",
          // }}
        />
      )}
    </div>
  );
};

export default AudioPreview;
