import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { BsCheckCircle } from "react-icons/bs";
import axiosInstance from "../../../../axios/axios";
import AppReqCalendar from "../../../../assets/images/app_req_calendar.png";
import AppReqTelehealth from "../../../../assets/images/app_req_telehealth.png";
import AppReq from "../../../../assets/images/appointment.png";
import { Button } from "./styles";
import {
  RowHead,
  DataDiv,
  Text,
  Modal,
  ModalContainer,
  AlertModalDialog,
  Image,
} from "../styles";
import { Data } from "../chats/Style";
import { Row } from "../Message/styles";
var CryptoJS = require("crypto-js");
const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};
if (localStorage.getItem("token") === "undefined undefined") {
  localStorage.clear();
  localStorage.setItem("logout", true);
}

const AppointmentRequest = (props) => {
  const [ModalAnimat] = useState(true);
  const [ActionButton, setActionButton] = useState("");
  const [AppointmentData, setAppointmentData] = useState(props.appData);
  const [AppReqDataCount, setAppReqDataCount] = useState("");
  const [AlertColor, setAlertColor] = useState("");
  const [ModalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);

  const ref = useRef([]);

  let history = useHistory();

  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozo");

  const ActionClick = (ind, id) => {
    var lastindex = props.appData.length - 1;

    if (ActionButton === ind) {
      setActionButton("");
      return;
    } else {
      setActionButton(ind);
      if (lastindex > 1) {
        if (lastindex === ind) {
          const element = document.getElementById(id);
          setTimeout(
            () =>
              element.scrollIntoView({
                behavior: "smooth",
                block: "end",
              }),
            500
          );
        }
      }
      return;
    }
  };

  const addToRefs = (el) => {
    if (el && !ref.current.includes(el)) {
      if (el.id !== undefined) {
        ref.current.push(el);
      }
    }
  };

  const setAcceptDecline = (val, id, type, cid) => {
    setActionButton("");
    const article = {
      value: val,
      id: id,
      type: cid,
    };
    let Data_enc = Encrypt_Value(article, "vozo");
    axiosInstance
      .put("/vozo/appointment/notification/update?uid=" + user_id, Data_enc)
      .then((response) => {
        let DecodeVal = Decrypt_Value(response.data, "vozo");

        if (val === "1") {
          if (cid === "1") {
            setAlertColor("green");
            setModalAlerShow(true);
            setModalMsg("Telehealth Appointment Accepted Successfully");
            setTimeout(() => setModalAlerShow(false), 2000);
            axiosInstance
              .get("/vozo/appointment/teleaccept?id=" + id)
              .then((response) => {})
              .catch((err) => {
                console.log(err);
              });
          } else {
            setAlertColor("green");
            setModalAlerShow(true);
            setModalMsg("Appointment Accepted Successfully");
            setTimeout(() => setModalAlerShow(false), 2000);
            axiosInstance
              .get("/vozo/appointment/accept?id=" + id)
              .then((response) => {})
              .catch((err) => {
                console.log(err);
              });
          }
        }
        if (val === "2") {
          setAlertColor("red");
          setModalAlerShow(true);
          setModalMsg("Appointment Declined Successfully");
          setTimeout(() => setModalAlerShow(false), 2000);
          axiosInstance
            .get("/vozo/appointment/decline?id=" + id)
            .then((response) => {})
            .catch((err) => {
              console.log(err);
            });
        }
        axiosInstance
          .get("/vozo/appointment/notification?uid=" + user_id)
          .then((response) => {
            let DecodeVal = Decrypt_Value(response.data, "vozo");
            setAppointmentData(DecodeVal);
            props.success(DecodeVal);
            props.successreload(DecodeVal);
          })
          .catch((err) => {
            console.log(err);
          });

        props.calRefHandle();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    var active = 0;
    active = AppointmentData.filter(
      (item) => item.pc_eventDate === moment().format("YYYY-MM-DD")
    );

    setAppReqDataCount(active.length);
  }, [AppointmentData]);

  useEffect(() => {
    if (props.show && props.refid !== "") {
      for (var i = 0; i < ref.current.length; i++) {
        if (ref.current[i].id == props.refid) {
          ActionClick(i, props.refid);
          break;
        }
      }
    } else {
      setActionButton("");
      let a = [];
      ref.current.push(a);
    }
  }, [props.refid, props.show, AppointmentData]);
  // changes by vijay
  return (
    <>
      <Row
        boxShadow="5px 5px 8px rgba(20, 46, 110, 0.15)"
        radius="8px"
        border=" 1px solid #cbd5e0"
        margin="8px 8px 22px 8px "
      >
        <Row borderBottom="1px solid #cbd5e0" padding="12px 16px 12px 16px">
          <RowHead>
            Today
            <DataDiv
              ml="8px"
              minWidth="16px"
              justifyContent="center"
              display="flex"
              aspectRatio="1/1"
              MaxWidth="24px"
              background="#2C7BE5"
              br="50px"
            >
              <Text
                textAlign="center"
                fontFamily="Inter"
                fontWeight="500"
                fontSize="12px"
                color="#ffffff"
                lineHeight="15px"
                height="100%"
                display="flex"
              >
                {AppReqDataCount}
              </Text>
            </DataDiv>
          </RowHead>
        </Row>
        <Data height="336px" width="100%">
          {AppointmentData &&
            AppointmentData.map((row, ind) => (
              <DataDiv
                id={row.pc_eid}
                padding="12px 12px 12px 16px"
                bb="1px solid #cbd5e0"
                cursor="pointer"
                onClick={(e) => ActionClick(ind, row.pc_eid)}
                ref={addToRefs}
                key={ind}
              >
                <DataDiv display="flex" mb="12px">
                  <Image
                    height="24px"
                    width="24px"
                    mr="12px"
                    src={AppReqCalendar}
                  ></Image>
                  <Text
                    display="flex"
                    fontFamily="Inter"
                    fontWeight="400"
                    fontSize="16px"
                    color=" #263446"
                    mr="8px"
                    lineHeight="19px"
                  >
                    {row.patient_name}
                  </Text>
                  <Text
                    display="flex"
                    fontFamily="Inter"
                    fontWeight="400"
                    fontSize="14px"
                    color="#718096"
                    lineHeight="17px"
                  >
                    (age {row.age})
                  </Text>
                </DataDiv>
                <DataDiv display="flex" ml="36px" mb="12px">
                  <Text
                    display="flex"
                    fontFamily="Inter"
                    fontWeight="500"
                    fontSize="15px"
                    color="#2C7BE5"
                    lineHeight="18px"
                    mr="5px"
                  >
                    {moment(row.pc_eventDate).format("ddd,MMM,DD,YYYY")} .
                    {moment(row.pc_startTime, "h:mm A").format("LT")}
                  </Text>
                  <Text
                    display="flex"
                    fontFamily="Inter"
                    fontWeight="400"
                    fontSize="14px"
                    color="#718096"
                    lineHeight="17px"
                  >
                    ({row.pc_duration} Min)
                  </Text>
                </DataDiv>
                <DataDiv display="flex" ml="36px">
                  {props.profileimage ? (
                    <>
                      <Image
                        src={props.profileimage}
                        borderRadius="40px"
                        height="24px"
                        width="24px"
                        mr="8px"
                      ></Image>
                    </>
                  ) : (
                    <DataDiv
                      textAlign="center"
                      br="40px"
                      height="24px"
                      width="24px"
                      mr="8px"
                      background="#A8AEBE"
                    >
                      <Text
                        fontFamily="Inter"
                        fontWeight="500"
                        fontSize="14px"
                        color="#ffffff"
                        lineHeight="16px"
                        mt="4px !important"
                      >
                        {" "}
                        {row.prov_name_split}
                      </Text>
                    </DataDiv>
                  )}
                  <Text
                    display="flex"
                    fontFamily="Inter"
                    fontWeight="400"
                    fontSize="14px"
                    color="#263446"
                    lineHeight="20px"
                    mr="20px"
                  >
                    {row.user_name}
                  </Text>

                  <Image
                    objectFit="none"
                    src={row.pc_telehealth === "1" ? AppReqTelehealth : AppReq}
                    height="24px"
                    mr="8px"
                  ></Image>
                  <Text
                    display="flex"
                    fontFamily="Inter"
                    fontWeight="500"
                    fontSize="14px"
                    color="#000000"
                    lineHeight="20px"
                  >
                    {row.pc_telehealth === 1 ? "Telehealth" : "Appointment"}
                  </Text>
                </DataDiv>

                {parseInt(ActionButton) === ind && (
                  <DataDiv display="flex" ml="3px" mt="20px">
                    <Text
                      display="flex"
                      fontFamily="Inter"
                      fontWeight="400"
                      fontSize="11px"
                      color="#718096"
                      lineHeight="13px"
                      mr="8px"
                    >
                      {moment(row.pc_time).fromNow() + " "}
                      From Patient portel
                    </Text>
                    <Button
                      height="32px"
                      width="82px"
                      border="1px solid #CBD5E0"
                      br="4px"
                      mr="12px"
                      background="none"
                      color="#718096"
                      onClick={() =>
                        setAcceptDecline(
                          "2",
                          row.pc_eid,
                          row.pc_title,
                          row.pc_telehealth
                        )
                      }
                    >
                      Decline
                    </Button>
                    <Button
                      border="1px solid #2c7be5"
                      height="32px"
                      width="82px"
                      br="4px"
                      bc="#2C7BE5"
                      color="#FFFFFF"
                      onClick={() =>
                        setAcceptDecline(
                          "1",
                          row.pc_eid,
                          row.pc_title,
                          row.pc_telehealth
                        )
                      }
                    >
                      Accept
                    </Button>
                  </DataDiv>
                )}
              </DataDiv>
            ))}
        </Data>
      </Row>
      <Modal show={ModalAlerShow}>
        <ModalContainer justifyContent="center">
          <AlertModalDialog color={AlertColor} animation={ModalAnimat}>
            <BsCheckCircle />
            {ModalMsg}
          </AlertModalDialog>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default AppointmentRequest;
