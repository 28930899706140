import axiosInstance from "../../axios/axios";
import { Decrypt_Value } from "../EncryptDecrypt";
// var PID = localStorage.getItem("NewPid");

const userID =
  localStorage.getItem("user_id") &&
  Decrypt_Value(localStorage.getItem("user_id"), "vozo");

export const RevisionApi = async (encounter) =>
  await axiosInstance.get(`/vozo/revision_get?encounter=${encounter}`);

export const FacilitySearchDataApi = async (Data) =>
  await axiosInstance.get(`/vozonew/facility/all?search=` + Data);

export const AppointmentSearchDataApi = async (Data) =>
  await axiosInstance.put(`/vozonew/getallappointmentsnotetake?search=` + Data);

export const NoteUpdateApi = async (id) =>
  await axiosInstance.put(`/vozo/appoinment/noteupdate?id=` + id);

export const GetSingleIncomingFaxApi = async (Data) =>
  await axiosInstance.post(`/vozo/patient/incomingfax`, Data);

export const SearchIncomingFaxApi = async (Data) =>
  await axiosInstance.get(`/vozo/patient/incomingfax/search?search=` + Data);

export const InsertIncomingFaxApi = async (Data) =>
  await axiosInstance.post(`/vozo/patient/incomingfax/insert`, Data);

export const GetSingleOutgoingFaxApi = async (Data) =>
  await axiosInstance.post(`/vozo/patient/outgoingfax`, Data);

export const InsertOutgoingFaxApi = async (Data) =>
  await axiosInstance.post(`/vozo/patient/outgoingfax/insert`, Data);
export const IncomingFaxProviderApi = async (Data) =>
  await axiosInstance.get(`/vozo/users/provider/retrieve?search=` + Data);
export const IncomingFaxAssignApi = async (Data) =>
  await axiosInstance.post(`/vozo/patient/faxassign`, Data);
export const ProvFaxDataApi = async (data) =>
  await axiosInstance.get(`/vozonew/appoinment/provfaxdata?search=` + data);

export const SaveFaxQueueApi = async (data) =>
  await axiosInstance.post(`/vozonew/appoinment/savefaxqueue`, data);
export const GetIncomingFaxApi = async (data) =>
  await axiosInstance.post(`/vozo/patient/Incomingfaxget`, data);

export const GetOutgoingFaxApi = async (data) =>
  await axiosInstance.post(`/vozo/patient/Outgoingfaxget`, data);

export const PreviewDownloadApi_1 = async (credential) =>
  await axiosInstance.put(
    `/vozo/patient/PreviewDownload_IncomingFax`,
    credential
  );

export const PreviewDownloadApi_2 = async (credential) =>
  await axiosInstance.put(
    `/vozo/patient/PreviewDownload_OutgoingFax`,
    credential
  );
export const RefferalListApi = async () =>
  await axiosInstance.get(`/vozo/refferalcontact/list`);

export const ProvidersListApi = async (data) =>
  await axiosInstance.get(`/vozo/internalproviders?search=` + data);
export const PatListApi = async (data) =>
  await axiosInstance.get(`/vozo/patientsearch?search=` + data);

export const ReferalDataApi = async (data) =>
  await axiosInstance.post(`/vozo/referaldata`, data);

export const ReferalDeleteApi = async (data) =>
  await axiosInstance.delete(
    `/vozo/referal/referalIn_Out/deletelist?id=` + data
  );

export const Ref_mngmt_PreviewApi = async ({ pid, id }) => {
  return await axiosInstance.get(`/vozo/refmngmt_preview?pid=${pid}&id=${id}`);
};

export const Ref_mngmt_UrlApi = async (imgfile) => {
  return await axiosInstance.post(`/vozo/document/img/base64`, imgfile);
};

export const referrerProviderSearchApi = async (data) => {
  return await axiosInstance.get(
    `/vozo/referral/provider/search?search=${data}`
  );
};

export const referrerDiagnosisApi = async (data) => {
  return await axiosInstance.get(
    `/vozo/referral/diagnosis/search?search=${data}`
  );
};

export const PatientDetailsApi = async (data) => {
  return await axiosInstance.post(`/vozo/referral/patient/details`, data);
};

export const ReferrelInsertApi = async (data) => {
  return await axiosInstance.post(`/vozo/referral/insert`, data);
};

export const singleReferrerApi = async (data) => {
  return await axiosInstance.post(`/vozo/single/referral?id=${data}`);
};

export const updateReferralApi = async (data) => {
  return await axiosInstance.post(`/vozo/update/referral`, data);
};
export const ReferalContactApi = async (data) =>
  await axiosInstance.post(`/vozo/referal/referalcontact/contactlist`, data);

export const ReferalInsertContactApi = async (data) =>
  await axiosInstance.post(`/vozo/referal/referalcontact/insertlist`, data);

export const ReferalContactSingleApi = async (data) =>
  await axiosInstance.get(`/vozo/referal/referalcontact/singlelist?id=` + data);

export const ReferalUpdateContactApi = async (data) =>
  await axiosInstance.put(`/vozo/referal/referalcontact/updatelist`, data);

export const ReferalDeleteContactApi = async (data) =>
  await axiosInstance.delete(
    `/vozo/referal/referalcontact/deletelist?id=` + data
  );

export const RefContactEmailCheckApi = async (data) =>
  await axiosInstance.put(`/vozo/referal/referalcontact/refemailcheck`, data);

export const SendFaxMailApi = async (data) => {
  return await axiosInstance.post(`/vozo/referralmngmt/sendfaxmail`, data);
};

export const ReferralDataOneApi = async (ref_id) => {
  return await axiosInstance.post(
    `/vozo/referralmngmt/referraldataone?search=` + ref_id
  );
};

export const ReferralDownloadApi = async (data) => {
  return await axiosInstance.post(`/vozo/referralmngmt/download`, data);
};

export const ReferralPreviewApi = async (data) => {
  return await axiosInstance.post(`/vozo/referralmngmt/preview`, data);
};

export const DeleteTempApi = async (data) => {
  return await axiosInstance.put(`/vozo/referralmngmt/deletetempdoc`, data);
};
export const ProvidersFaxMailApi = async (data) =>
  await axiosInstance.get(`/vozo/referralfaxmailprovider?search=` + data);

export const ProviderListApi = async () =>
  await axiosInstance.get(`/vozo/Providerlist?uid=${userID}`);

export const PatientLedgerApi = async (data) =>
  await axiosInstance.get(`/vozo/patient/patientledger?search=` + data);

export const PatientApi = async (data) =>
  await axiosInstance.get(`/vozo/singlepatientledger?pid=` + data);

export const PatientLedgerApi1 = async (data) =>
  await axiosInstance.get(`/vozo/patient/patientledgernolimit?search=` + data);
