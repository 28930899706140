import { takeLatest } from "redux-saga/effects";
import {
  GetFilteredData,
  GetPatEducationData,
  GetTagsData,
  GetAssigningData,
  GetAlertData,
} from "../../StateManagement/Reducers/PatientDemoHomeworkState";

import {
  PatEduWorker,
  PatTagsWorker,
  FilterResourceWorker,
  ResourceAssignWorker,
  alertWorker,
} from "./DemoHomeworkWorkers";

export function* PatEduSaga() {
  yield takeLatest(GetPatEducationData.type, PatEduWorker);
}

export function* PatTagsSaga() {
  yield takeLatest(GetTagsData.type, PatTagsWorker);
}

export function* FilterPatEdu() {
  yield takeLatest(GetFilteredData.type, FilterResourceWorker);
}
export function* AssignResourceSaga() {
  yield takeLatest(GetAssigningData.type, ResourceAssignWorker);
}

export function* AlertEduSaga() {
  yield takeLatest(GetAlertData.type, alertWorker);
}
