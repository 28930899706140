import React, { useState } from "react";
import styled from "styled-components";
import { IoIosStarOutline } from "react-icons/io";
import { IoIosStar } from "react-icons/io";

const StarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Star = styled.div`
  font-size: ${(props) => props.size || "40px"};
  color: ${(props) =>
    props.isFilled ? props.activeColor : props.inactiveColor};
  cursor: pointer;
  margin: 0 2px;
`;

const StarRating = ({
  count = 5,
  size = "50px",
  activeColor = "#ffd700",
  inactiveColor = "#d3d3d3",
  initialRating = 0,
  onChange,
}) => {
  const [rating, setRating] = useState(initialRating);

  const handleClick = (newRating) => {
    setRating(newRating);
    if (onChange) onChange(newRating);
  };

  const renderStar = (index) => {
    const isFilled = index < rating;
    return (
      <Star
        key={index}
        size={size}
        activeColor={activeColor}
        inactiveColor={inactiveColor}
        isFilled={isFilled}
        onClick={() => handleClick(index + 1)}
      >
        {isFilled ? <IoIosStar /> : <IoIosStarOutline />}
      </Star>
    );
  };

  return (
    <StarContainer>
      {Array.from({ length: count }).map((_, index) => renderStar(index))}
    </StarContainer>
  );
};

export default StarRating;
