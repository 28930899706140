import { takeLatest } from "redux-saga/effects";
import {
  AddDrugData,
  DestroyDrugData,
  GetManagementDrugs,
  GetEditDrugs,
  GetCPT4Codes,
  GetDestroyedDrugs,
} from "../../StateManagement/Reducers/SettingState";
import {
  AddDrugDataWork,
  DestroyDrugDataWork,
  GetManagementDrugsWork,
  GetEditDrugsWork,
  GetCPT4CodesWork,
  GetDestroyedDrugsWork,
} from "./SettingSagaWorkers";

export function* AddDrugDataSaga() {
  yield takeLatest(AddDrugData.type, AddDrugDataWork);
}
export function* DestroyDrugDataSaga() {
  yield takeLatest(DestroyDrugData.type, DestroyDrugDataWork);
}
export function* GetManagementDrugsSaga() {
  yield takeLatest(GetManagementDrugs.type, GetManagementDrugsWork);
}
export function* GetCPT4CodesSaga() {
  yield takeLatest(GetCPT4Codes.type, GetCPT4CodesWork);
}
export function* GetEditDrugsSaga() {
  yield takeLatest(GetEditDrugs.type, GetEditDrugsWork);
}
export function* GetDestroyedDrugsSaga() {
  yield takeLatest(GetDestroyedDrugs.type, GetDestroyedDrugsWork);
}
