import { call, put } from "redux-saga/effects";

import { Encrypt_Value, Decrypt_Value } from "../EncryptDecrypt";

import {
  patEdDataApi,
  patTagApi,
  filterResourceApi,
  ResourceAssignApi,
} from "../Apis/DemographicHomeworkApi";
import {
  setTagsData,
  setPatEducationData,
  setFilteredData,
  setPatTenEduData,
  setAlertData,
} from "../../StateManagement/Reducers/PatientDemoHomeworkState";

export function* PatEduWorker({ payload }) {
  const credential = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(patEdDataApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      if (payload.includes("&offset=0")) {
        yield put(setPatEducationData(Data));
      } else {
        yield put(setPatTenEduData(Data));
      }
    }
  } catch (e) {
    if (payload.includes("&offset=0")) {
      yield put(setPatEducationData([]));
    } else {
      console.log(e.message);
    }
  }
}

export function* PatTagsWorker({ payload }) {
  const credential = payload;
  try {
    const res = yield call(patTagApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");

      yield put(setTagsData(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* FilterResourceWorker({ payload }) {
  if (payload === 0) {
    let a = [];
    yield put(setFilteredData(a));
  } else {
    const credential = Encrypt_Value(payload, "vozo");
    try {
      const res = yield call(filterResourceApi, credential);
      if (res.status === 200) {
        const Data = Decrypt_Value(res.data, "vozo");
        yield put(setFilteredData(Data));
      }
    } catch (e) {
      console.log(e.message);
    }
  }
}

export function* ResourceAssignWorker({ payload }) {
  const credential = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(ResourceAssignApi, credential);
    if (res.status === 200) {
      yield put(setAlertData(true));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* alertWorker() {
  try {
    yield put(setAlertData(false));
  } catch (e) {
    console.log(e.message);
  }
}
