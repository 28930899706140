import styled from "styled-components";
import {
  ModalHeader,
  Button_1,
  Table,
  TableHeadCell,
} from "../../../components/setting/PatientPortalSettings/StyledComponents";
export const Div = styled.div`
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  width: ${(p) => p.width && p.width};
`;
export const Modclosebtn = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  margin-left: auto;
  line-height: 1.15px;
  margin-right: 20px;
  svg {
    padding-right: 2px;
  }
`;
export const ModalHead = styled(ModalHeader)`
  border-bottom: 1px solid #cbd5e0;
`;
export const ModalTitle = styled(Div)`
  font-family: "Inter";
  font-weight: 600;
  font-size: 24px;
`;
export const ModalFooter = styled(Div)`
  border-top: 1px solid #cbd5e0;
  margin: 20px 0 0 0;
`;
export const Button = styled(Button_1)`
  height: 34px;
  border-radius: 4px;
  border: none;
  margin: ${(p) => p.margin && p.margin};
  &:hover {
    background-color: ${(p) => p.hrBgColor && p.hrBgColor};
  }
`;
export const ButtonGroup = styled.div`
  padding: 20px 24px;
`;
export const H5 = styled.h5`
  color: ${(p) => p.color && p.color};
  font-family: "Inter";
  padding: ${(p) => p.padding && p.padding};
  font-weight: ${(p) => p.weight && p.weight};
`;
export const TableCont = styled(Table)`
  display: ${(p) => p.display && p.display};
  max-height: 165px;
`;
export const Tr = styled.tr`
  background-color: ${(p) => p.bgColor && p.bgColor};
  color: #777984;
  height: 40px;
  margin: ${(p) => p.margin && p.margin};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
`;
export const Th = styled(TableHeadCell)`
  padding: 0 0 0 15px;
  &:last-child {
    width: 100px;
    padding: 0 20px 0 15px;
  }
  &:first-child {
    padding: 0 0 0 20px;
  }
`;
export const Td = styled.td`
  padding: 0 0 0 15px;
  border-bottom: 1px solid #cbd5e0;
  &:last-child {
    width: 100px;
    padding: 0 20px 0 15px;
  }
  &:first-child {
    padding: 0 0 0 20px;
  }
`;
export const Atag = styled.a``;
export const Supportclosebtn = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  line-height: 1.15px;
  background-color: white;
    margin-top: 13px;
}
  svg {
    padding-right: 2px;
  }
`;
