import React, { useState, useEffect } from "react";
import moment from "moment";
import axiosInstance from "../../../../axios/axios";
import PatientSearch from "../../../../assets/images/search_patient_chat.png";
import { RowHead, Image, Text, DataDiv, Avatar, AvatarLetter } from "../styles";
import { Search, SearchInputText, Row, TnumbComponent } from "./styles";
import { Data } from "../chats/Style";
import {
  Dropdown,
  DropdownItems,
} from "../../../billing/CardTransactions/styles";

var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

const Messages = (props) => {
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  var authorized =
    localStorage.getItem("authorized") &&
    Decrypt_Value(localStorage.getItem("authorized"), "vozo");
  var main_pro =
    localStorage.getItem("main_pro") &&
    Decrypt_Value(localStorage.getItem("main_pro"), "vozo");
  var owner_id =
    localStorage.getItem("owner_id") &&
    Decrypt_Value(localStorage.getItem("owner_id"), "vozo");
  var storedNames =
    localStorage.getItem("setroles") &&
    Decrypt_Value(localStorage.getItem("setroles"), "vozo");

  const [SecMsgList, setSecMsgList] = useState([]);
  const [temp, setTemp] = useState([]);

  const [dis, setDis] = useState(false);
  const [search, setSearch] = useState("");
  const [details, setDetails] = useState("");
  const [patNames, setPatNames] = useState([]);
  const [msgCount, setMsgCount] = useState("0");
  const [tempPatPic, setTempPatPic] = useState("");
  const [loadingIS, setLoadingIS] = useState(false);

  var DBHost = Decrypt_Value(localStorage.getItem("DBHost"), "vozo");
  let finalurl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    finalurl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }

  if (
    firstDot[0] === "localhost" ||
    firstDot[0] === "test" ||
    firstDot[0] === "mumbai" ||
    firstDot[0] === "production" ||
    firstDot[0] === "ehr" ||
    firstDot[0] === "ehr1" ||
    firstDot[0] === "dev"
  ) {
    DBHost = "default";
  } else {
    DBHost = firstDot[0];
  }

  var Test_URL = finalurl + "/sites/" + DBHost + "/documentimg/";

  const handleChange = (e) => {
    setDis(true);
    setSearch(e.target.value);
    setDetails("");
  };

  const handleSelect = (e) => {
    setSearch(e.name);
    setDetails(e.pid);
    setDis(false);
    setTempPatPic(e.img_url);
  };

  const timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useEffect(() => {
    if (details !== "") {
      setSecMsgList([]);
      let a = [];

      temp.map((row, ind) => {
        if (row.pid === details) {
          if (row.message === null) {
            row.message = "";
            a.push(row);
          } else {
            a.push(row);
          }
          setSecMsgList(a);
        }
      });

      if (a.length <= 0) {
        let obj = {
          img_url: tempPatPic,
          message: "",
          PatName: search,
          unread: "0",
          date: "",
          pid: details,
        };

        a.push(obj);
        setSecMsgList(a);
      }
    } else {
      setSecMsgList(props.secMsgData);
    }

    setTemp(props.secMsgData);
    setMsgCount(props.MsgCount);
  }, [details, props]);

  useEffect(() => {
    let isCancelled = false;

    const handlechange = async () => {
      setLoadingIS(true);

      await timeout(1000);
      if (!isCancelled) {
        if (search !== "") {
          let Data_ =
            "limit=10&fname=" +
            search +
            "&authorized=" +
            authorized +
            "&main_pro=" +
            main_pro +
            "&userID=" +
            user_id +
            "&pat_share=" +
            storedNames.pat_share +
            "&owner_id=" +
            owner_id;

          let Data_1 = Encrypt_Value(Data_, "vozo");

          axiosInstance
            .get("/vozo/patientsearchportal?search=" + Data_1)
            .then((response) => {
              let DecodeVal = Decrypt_Value(response.data, "vozo");

              setPatNames(DecodeVal);
              setLoadingIS(false);
            })

            .catch((err) => {
              setPatNames(["No result"]);
              setLoadingIS(false);
            });
        }
      }
    };

    handlechange();
    return () => {
      isCancelled = true;
    };
  }, [search]);

  return (
    <>
      <Row
        boxShadow="5px 5px 8px rgba(20, 46, 110, 0.15)"
        radius="8px"
        border=" 1px solid #cbd5e0"
        margin="8px 8px 22px 8px "
      >
        <Row padding="12px 16px 12px 16px">
          <RowHead>
            Today
            {msgCount !== "0" && (
              <DataDiv
                ml="8px"
                minWidth="16px"
                // width="max-content"
                // height="max-content"
                aspectRatio="1/1"
                MaxWidth="24px"
                background="#2C7BE5"
                br="50px"
                justifyContent="center"
                display="flex"
              >
                <Text
                  textAlign="center"
                  fontFamily="Inter"
                  fontWeight="500"
                  fontSize="12px"
                  color="#ffffff"
                  lineHeight="15px"
                  height="100%"
                  display="flex"
                >
                  {msgCount}
                </Text>
              </DataDiv>
            )}
          </RowHead>
        </Row>
        <Search>
          <Image
            src={PatientSearch}
            mt="-3px"
            objectFit="none"
            mr="8px"
          ></Image>
          <SearchInputText
            onChange={(e) => handleChange(e)}
            placeholder="Search Patient"
            value={search}
          />
          <Dropdown
            selected={dis}
            active={search}
            flexDirection="column"
            width="96%"
            position="absolute"
            top="130px"
            left="7px"
            border="1px solid #DBDBDB"
            family="Inter"
            size="14px"
            lineheight="19px"
            weight="400"
          >
            {patNames.length !== 0 ? (
              <>
                {patNames.map((r, i) => {
                  return (
                    <DropdownItems
                      key={i}
                      width="100%"
                      family="Inter"
                      size="14px"
                      lineheight="19px"
                      weight="400"
                      minheight="34px"
                      maxheight="max-content"
                      backgroundColor="#ffff"
                      padding="4px"
                      cursor="pointer"
                      onClick={() => handleSelect(r)}
                    >
                      {loadingIS ? "Loading..." : r.text}
                    </DropdownItems>
                  );
                })}
              </>
            ) : (
              <DropdownItems
                width="100%"
                height="max-content"
                backgroundColor="#ffff"
                padding="4px"
                family="Inter"
                size="14px"
                lineheight="19px"
                weight="400"
              >
                {loadingIS ? "Loading..." : "No result Found"}
              </DropdownItems>
            )}
          </Dropdown>
        </Search>
        <Data height="308px" width="100%">
          {SecMsgList.map((row, ind) => (
            <>
              {(row.message !== null || row.img_url !== null) && (
                <Row
                  borderBottom="1px solid #cbd5e0"
                  dis="flex"
                  padding="20px 20px 12px 20px"
                  cursor="pointer"
                  onClick={() => {
                    props.handleChat(row);
                  }}
                >
                  {row.img_url === null ? (
                    <Avatar
                      width="32px"
                      height="32px"
                      border="1px solid #ffffff"
                    >
                      <AvatarLetter top="6px" left="9px">
                        {row.PatName.charAt(0).toUpperCase()}
                      </AvatarLetter>
                    </Avatar>
                  ) : (
                    <Image
                      src={Test_URL + row.img_url}
                      borderRadius="40px"
                      height="32px"
                      width="32px"
                      mr="12px"
                    />
                  )}
                  <Row
                    dis="flex"
                    flexDirection="column"
                    width="87%"
                    padding="0px 0px 0px 0px"
                  >
                    <Row dis="flex" justifyContent="space-between" width="100%">
                      <Text
                        fontFamily="Inter"
                        fontWeight="500"
                        fontSize="14px"
                        color="#000000"
                        lineHeight="16.94px"
                      >
                        {row.PatName}
                      </Text>
                      {row.date !== "" && (
                        <Text
                          fontFamily="Inter"
                          fontWeight="500"
                          fontSize="12px"
                          lineHeight="14px"
                          color="#B9B9B9"
                        >
                          {row.date &&
                          moment(row.date).format("YYYY-MM-DD") ===
                            moment().format("YYYY-MM-DD")
                            ? moment(row.date).format("h:mm a")
                            : moment(row.date).calendar(null, {
                                lastDay: "[Yesterday]",
                                lastWeek: "DD/MM/YYYY",
                                sameElse: "DD/MM/YYYY",
                              })}
                        </Text>
                      )}
                    </Row>
                    <Row width="100%" dis="flex">
                      <Text
                        fontFamily="Inter"
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="14.92px"
                        color="#B9B9B9"
                        whiteSpace="nowrap"
                        width="200px"
                        textOverflow="ellipsis"
                        overflow="hidden"
                      >
                        {row.type === "image" ? (
                          <TnumbComponent
                            message={row.message}
                            variant="image"
                          />
                        ) : row.type === "doc" ? (
                          <TnumbComponent message={row.message} variant="doc" />
                        ) : row.type === "audio" ? (
                          <TnumbComponent
                            message={row.message}
                            variant="audio"
                          />
                        ) : (
                          row.message
                        )}
                      </Text>
                      {row.unread !== "0" && (
                        <Row
                          width="16px"
                          height="16px"
                          backgroundColor="#2C7BE5"
                          radius="50%"
                          color="#ffff"
                          margin="0px 0px 0px auto"
                        >
                          <Text
                            textAlign="center"
                            fontFamily="Inter"
                            fontWeight="500"
                            fontSize="12px"
                            color="#ffffff"
                            lineHeight="15px"
                          >
                            {row.unread}
                          </Text>
                        </Row>
                      )}
                    </Row>
                  </Row>
                </Row>
              )}
            </>
          ))}
        </Data>
      </Row>
    </>
  );
};

export default Messages;
