import { takeLatest } from "redux-saga/effects";
import {
  GetAuditLogData,
  GetPatientSearchData,
  GetUserSearchData,
  GetBillingFacility,
  GetPracticeDashboard,
  GetDeleteInvoice,
  GetTotalCreditAmt,
  GetAddPayment,
} from "../../StateManagement/Reducers/ScheduleState";
import {
  AuditLog,
  PatientSearchData,
  UserSearchData,
  BillingFacilityWorker,
  PracticeDashboard,
  deleteInvoiceData,
  TotalCreditAmtData,
  AddPaymentAmt,
} from "./ScheduleSagaWorkers";

export function* AuditLogSaga() {
  yield takeLatest(GetAuditLogData.type, AuditLog);
}
export function* PatientSearchDataSaga() {
  yield takeLatest(GetPatientSearchData.type, PatientSearchData);
}
export function* UserSearchDataSaga() {
  yield takeLatest(GetUserSearchData.type, UserSearchData);
}

export function* BillingFacilitySaga() {
  yield takeLatest(GetBillingFacility.type, BillingFacilityWorker);
}

export function* PracticeDashboardSaga() {
  yield takeLatest(GetPracticeDashboard.type, PracticeDashboard);
}

export function* DeleteInvSaga() {
  yield takeLatest(GetDeleteInvoice.type, deleteInvoiceData);
}

export function* TotalInvoiceSage() {
  yield takeLatest(GetTotalCreditAmt.type, TotalCreditAmtData);
}

export function* AddPaymentAmtSaga() {
  yield takeLatest(GetAddPayment.type, AddPaymentAmt);
}
