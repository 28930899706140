import { TextArea } from "semantic-ui-react";
import styled from "styled-components";

export const BoxContainer = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MyLabel = styled.h1`
  margin-top: 10px;
`;
export const Output = styled.h3`
  margin-top: 10px;
  margin: auto;
`;

export const Div1 = styled.div`
  display: flex;
`;
export const Div2 = styled.div``;

export const InputBox = styled(TextArea)`
  margin: 20px;
  resize: none;
  outline: none;
  overflow: auto;
  height: 200px;
  width: 50%;
`;

export const MyButton = styled.button`
  border: none;
  background-color: #5e81f4;
  color: #fff;
  margin-left: 20px;
  width: 300px;
  margin-right: 20px;
  height: 40px;
  width: 80px;
  border-radius: 6px;
`;

export const CopyButton = styled.button`
  border: none;
  background-color: #808080;
  color: #fff;
  margin-left: 20px;
  width: 300px;
  margin-right: 20px;
  height: 30px;
  width: 80px;
  border-radius: 6px;
`;
