import { takeLatest } from "redux-saga/effects";
import {
  SendMsgData,
  getMessagerList,
  getPreviewDownload,
  getSecureMessageList,
  getSecureSearch,
  getTotalUnreadCount,
  getUnreadCount,
  gettotalUnreadCount,
} from "../../StateManagement/Reducers/MessageState";
import {
  MessageList,
  PreviewDownloadApi,
  SecureMessageList,
  SecureMessagePatientSearch,
  SendMsgWorker,
  TotalUnreadCount,
  UnreadCountUpdate,
} from "./MessageSagaWorker";

export function* MsgSaga() {
  yield takeLatest(SendMsgData.type, SendMsgWorker);
}

export function* MessagerListSaga() {
  yield takeLatest(getMessagerList.type, MessageList);
}

export function* PreviewDownloadSaga() {
  yield takeLatest(getPreviewDownload.type, PreviewDownloadApi);
}

export function* UnreadCountUpdateSaga() {
  yield takeLatest(getUnreadCount.type, UnreadCountUpdate);
}
export function* TotalUnreadCountSaga() {
  yield takeLatest(gettotalUnreadCount.type, TotalUnreadCount);
}

export function* SecureMessageListSaga() {
  yield takeLatest(getSecureMessageList.type, SecureMessageList);
}

export function* SecureMessageSearchSaga() {
  yield takeLatest(getSecureSearch.type, SecureMessagePatientSearch);
}
