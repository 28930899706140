import axiosInstance from "../../axios/axios";

export const patEdDataApi = async (data) =>
  await axiosInstance.get(`/vozo/patient/resourceofpatient?search=` + data);

export const patTagApi = async (data) =>
  await axiosInstance.get(`/vozo/patient/gettagslist?id=` + data);

export const filterResourceApi = async (data) =>
  await axiosInstance.post(`/vozo/patient/filteredresource`, data);

export const ResourceAssignApi = async (data) =>
  await axiosInstance.put(
    `/vozo/patient/patientEducationAllocate/demograph`,
    data
  );
