import { createSlice } from "@reduxjs/toolkit";

export const DemographicsHomework = createSlice({
  name: "DemographicsHomework",
  initialState: {
    PatEducationData: [],
    TagsData: [],
    filteredData: [],

    filterLoading: false,
    alert: false,
  },
  reducers: {
    GetPatEducationData(state) {
      return state;
    },
    setPatEducationData(state, action) {
      state.PatEducationData = action.payload;
    },
    setPatTenEduData(state, action) {
      state.PatEducationData = [...state.PatEducationData, ...action.payload];
    },
    GetTagsData(state) {
      return state;
    },
    GetAssigningData(state) {
      return state;
    },
    setAlertData(state, action) {
      state.alert = action.payload;
    },
    GetAlertData(state) {
      return state;
    },

    setTagsData(state, action) {
      state.TagsData = action.payload;
    },
    GetFilteredData(state) {
      state.filterLoading = true;
    },
    setFilteredData(state, action) {
      state.filterLoading = false;
      state.filteredData = action.payload;
    },
  },
});

export const {
  GetPatEducationData,
  setPatEducationData,
  GetTagsData,
  setTagsData,
  GetFilteredData,
  setFilteredData,
  setPatTenEduData,
  GetAssigningData,
  setAlertData,
  GetAlertData,
} = DemographicsHomework.actions;

export default DemographicsHomework.reducer;
