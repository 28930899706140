import axiosInstance from "../../axios/axios";

export const AuditLogApi = async (Data) =>
  await axiosInstance.post(`/vozo/getAuditLogData`, Data);

export const PatientSearchDataApi = async (Data) =>
  await axiosInstance.get(`/vozo/patientsearch?search=` + Data);

export const UserSearchDataApi = async (Data) =>
  await axiosInstance.get(`/vozo/usersearch?search=` + Data + "&status=" + 1);

export const BillingFacilityApi = async (Data) =>
  await axiosInstance.get("/vozo/getbillingfacility");

export const PracticeDashboardApi = async (Data) =>
  await axiosInstance.post(`/vozo/getPracticeDashboardData`, Data);

export const DeleteInvoiceApi = async (Data) =>
  await axiosInstance.put(`/vozo/delete_inc`, Data);

export const TotalCreditAmtApi = async (eid) =>
  await axiosInstance.get("/vozo/pat_total?eid=" + eid);

export const TotalInvoiceIdApi = async (eid, Data) =>
  await axiosInstance.put("/vozo/totalinvoiceid?eid=" + eid, Data);

export const PatCreditPayApi = async (eid, Data) =>
  await axiosInstance.put(`/vozo/pat_cre_pay?eid=` + eid, Data);
