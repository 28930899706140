import styled from "styled-components";
import { Text } from "../../../StyledComponents";

export const Container = styled.div`
  width: 480px;
  height: 420px;
  background: rgb(255, 255, 255);
  box-shadow: rgba(196, 203, 214, 0.1) 0px 6px 58px;
  border-radius: 16px;
  text-align: center;
  padding: 40px 24px;
`;

export const Title = styled(Text)``;

export const FormGroupInline = styled.div`
  display: ${(p) => (p.display ? p.display : "grid")};
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
  width: 79.5%;
  margin-left: ${(p) => p.ml && p.ml};
  margin-right: ${(p) => p.mr && p.mr};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
`;

export const Input = styled.input`
  background: #ffffff;
  color: ${(p) => p.color && p.color};
  border: 1px solid #d8e0f0;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.222055);
  border-radius: 8px;
  height: ${(p) => (p.height ? p.height : "48px")};
  width: ${(p) => p.width && p.width};
  padding: ${(p) => (p.padding ? p.padding : "0px 18px")};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "24px")};
  &:focus {
    outline: none;
  }
  &:disabled {
    background: #f9f8f8;
    &:hover {
      cursor: not-allowed;
    }
  }
  font-size: ${(p) => (p.fontSize ? p.fontSize : "14px")};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  text-align: ${(p) => p.textAlign && p.textAlign};
`;

export const ResendButton = styled.span`
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  font-family: "open sans";
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: 26px;
  color: rgba(64, 123, 255, 1);
`;
