import { createSlice } from "@reduxjs/toolkit";

export const ReportSlice = createSlice({
  name: "Report",
  initialState: {
    Loading: true,
    RepoLoading: true,
    Refresh: "",
    ClinicalReportDetails: [],
    ClinicalReportCount: [],
    ClinicalReportLoad: [],
    Currency: localStorage.getItem("currency_symbol")
      ? localStorage.getItem("currency_symbol")
      : "$",
  },
  reducers: {
    GetClinicalReportDetails: (state, action) => {
      if (action.payload.loadval !== "loadmore") {
        state.Loading = true;
      } else {
        state.RepoLoading = true;
      }
      state.Refresh = "";
    },

    SetClinicalReportDetails: (state, action) => {
      state.ClinicalReportDetails = action.payload;
      state.Loading = false;
      state.RepoLoading = false;
    },

    SetClinicalReportLoad: (state, action) => {
      state.ClinicalReportDetails = [
        ...state.ClinicalReportDetails,
        ...action.payload,
      ];
      state.RepoLoading = false;
      state.Loading = false;
    },

    GetClinicalReportCount: (state) => {
      state.Refresh = "";
    },

    SetClinicalReportCount: (state, action) => {
      state.ClinicalReportCount = action.payload;
    },

    GetCurrency: (state) => {
      state.Refresh = "";
    },

    SetCurrency: (state, action) => {
      state.Currency = action.payload;
      state.Loading = false;
    },

    // Refresh: (state, action) => {
    //   if (isNaN(action.payload)) {
    //     alert("Its Not a number");
    //   } else {
    //     let message = "";
    //     if (action.payload === 1)
    //       message = "Profile Picture Updated Successfully";
    //     if (action.payload === 2)
    //       message = "Profile Picture Deleted Successfully";

    //     if (action.payload === 3)
    //       message = "Details Sent to Review Successfully";
    //   }

    //   state.Refresh = action.payload;
    // },
  },
});

export const {
  GetClinicalReportDetails,
  SetClinicalReportDetails,
  Refresh,
  GetClinicalReportCount,
  SetClinicalReportCount,
  SetClinicalReportLoad,
  GetCurrency,
  SetCurrency,
} = ReportSlice.actions;

export default ReportSlice.reducer;
