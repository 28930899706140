import { takeLatest } from "redux-saga/effects";
import {
  GetRecoverData,
  getAccountVerification,
  getTemporaryRes,
} from "../../StateManagement/Reducers/SubscriptionRenewalState";

import {
  AccountVerification,
  RecoverDataRes,
  TemporaryRes,
} from "./SubscriptionRenewalSagaWorker";

export function* AccountVerificationSaga() {
  yield takeLatest(getAccountVerification.type, AccountVerification);
}

export function* TemporaryResSaga() {
  yield takeLatest(getTemporaryRes.type, TemporaryRes);
}
