import React, { useState } from "react";
import { Button, Div, Form, FormLabel, Text } from "../../../StyledComponents";
import {
  Container,
  FormGroupInline,
  Input,
  ResendButton,
  Title,
} from "./style";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Decrypt_Value,
  Encrypt_Value,
} from "../../../MiddleWare/EncryptDecrypt";
import {
  GetRecoverData,
  getTemporaryRes,
  setTemporaryOtp,
  getAccountVerification,
  setLoaderCall,
  setTemporaryRes,
  setAccountVerification,
} from "../../../StateManagement/Reducers/SubscriptionRenewalState";

import Loader from "../../../components/popups/Loading";
import MessageModal from "../../../components/login/ForgotPassword/MessageModal";
import axiosInstance from "../../../axios/axios";
import axios from "axios";

const EmailVerification = () => {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //

  const dispatch = useDispatch();
  let history = useHistory();

  const [Otp, setOtp] = useState(new Array(4).fill(""));
  const [showTimer, setShowTimer] = useState(true);
  const [timer, setTimer] = useState(600);
  const [otpErr, setOtpErr] = useState(false);
  const [btndis, setbtndis] = useState(false);
  const [btndis1, setbtndis1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show1, setShow1] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [spinner, setspinner] = useState(false);

  var user_id = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  const TemporaryEmail = useSelector(
    (state) => state.SubsRenewal?.TemporaryEmail
  );
  const TemporaryRes = useSelector((state) => state.SubsRenewal?.TemporaryRes);
  const AccountVerification = useSelector(
    (state) => state.SubsRenewal?.AccountVerification
  );
  const Reactiverecover = useSelector(
    (state) => state.SubsRenewal?.ReactiveRecover
  );

  var Accountotp =
    localStorage.getItem("AccountOTP") &&
    Decrypt_Value(localStorage.getItem("AccountOTP"), "vozo");

  let ZipUrl = "";
  let firstDot = window.location.hostname.split(".");
  if (firstDot[0] !== "localhost") {
    ZipUrl = process.env.REACT_APP_BACKEND_FILE_ACCESS.replace(
      "oemr",
      firstDot[0]
    );
  } else {
    ZipUrl = process.env.REACT_APP_BACKEND_FILE_ACCESS;
  }
  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------- Functions Start--------------------------------------------------------- //

  const HandleVerify = (e) => {
    e.preventDefault();
    setbtndis(true);
    if (Otp.join("") == "") {
      setOtpErr(true);
      setbtndis(false);
      return false;
    }
    if (!showTimer) {
      setShow2(true);
      setModalMsg("OTP time has expired. Generate a new code.");
      setTimeout(() => {
        setShow2(false);
        setModalMsg("");
      }, 1500);
      setbtndis(false);
      return false;
    }
    if (Accountotp == Otp.join("")) {
      dispatch(setTemporaryOtp("Success"));
      if (Reactiverecover === "reactive_acct") {
        let article = {
          email: TemporaryEmail.email_id,
          user_id: user_id,
        };
        dispatch(getTemporaryRes(article));
      } else {
        setspinner(true);
        // dispatch(GetRecoverData(""));

        axiosInstance
          .get("/vozo/get/create_exportdata")
          .then((response) => {
            let decryptedResponse = Decrypt_Value(response.data, "vozo");
            let article1 = {
              password: decryptedResponse,
            };
            let encryptedData1 = Encrypt_Value(article1, "vozo");
            try {
              axios
                .get(ZipUrl + "/zipfile/index.php", {
                  params: encryptedData1,
                })
                .then((response) => {
                  const article = {
                    user_id: user_id,
                    password: decryptedResponse,
                    type: "recover_data",
                  };

                  let encryptedData = Encrypt_Value(article, "vozo");
                  axiosInstance
                    .post("/vozo/sendzipemail", encryptedData)
                    .then((response) => {
                      setspinner(false);
                      history.push("/verificationdone");
                    })
                    .catch(() => {
                      console.log("not successful");
                    });
                })
                .catch((err) => {
                  console.log("failed");
                });
            } catch (e) {
              console.log(e.message);
            }
          })
          .catch(() => {
            setspinner(false);
            console.log("not successful");
          });
      }
    } else {
      dispatch(setTemporaryOtp("Failure"));
      localStorage.setItem("current_verification_timer", timer);
      history.push("/verificationdone");
    }
  };

  const HandleOtp = (e, i) => {
    setOtpErr(false);
    if (isNaN(e.value)) return;

    setOtp([...Otp.map((r, ind) => (ind === i ? e.value : r))]);

    if (e.parentElement.nextSibling) {
      e.parentElement.nextSibling.firstChild.focus();
    }
  };

  const KeyDown = (e) => {
    if (e.keyCode === 8) {
      if (e.target.parentElement.previousSibling) {
        e.target.parentElement.previousSibling.firstChild.focus();
      }
    }
  };

  const FieldPaste = (evt, i) => {
    const pasteVal = evt.clipboardData.getData("Text").toString();
    setOtpErr(false);
    if (isNaN(pasteVal)) return;
    setOtp([...Otp.map((r, ind) => pasteVal.at(ind))]);
  };

  const HandleResend = () => {
    setbtndis1(true);
    localStorage.setItem("AccountOTP", "");
    setOtp(new Array(4).fill(""));

    let article = {
      email: TemporaryEmail.email_id,
      user_id: user_id,
      type: Reactiverecover,
    };
    dispatch(getAccountVerification(article));
    setShowTimer(true);
    localStorage.setItem("current_verification_timer", null);
  };
  // ------------------------------- Functions End--------------------------------------------------------- //

  // ------------------------------- UseEffect Start ------------------------------------------------------- //
  useEffect(() => {
    const storedTimer = localStorage.getItem("current_verification_timer");
    if (storedTimer !== null) {
      setTimer(parseInt(storedTimer, 10));
    }
  }, []);

  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer === 0) {
      localStorage.setItem("AccountOTP", "");
      setShowTimer(false);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);

  useEffect(() => {
    if (Object.keys(TemporaryRes).length !== 0) {
      if (TemporaryRes?.status !== "") {
        setbtndis(false);
        dispatch(setTemporaryRes({}));
        history.push("/verificationdone");
      }
    }
  }, [TemporaryRes]);

  useEffect(() => {
    if (AccountVerification.status === "Success") {
      setShow1(true);
      setModalMsg("Mail sent Successfully");
      setTimer(600);
      setTimeout(() => {
        setShow1(false);
      }, 2000);

      setbtndis1(false);
      dispatch(setAccountVerification({}));
    } else if (AccountVerification.status === "Failure") {
      if (AccountVerification.output === "Email sent failed") {
        setShow2(true);
        setModalMsg("Mail not sent  Successfully");
        setTimeout(() => {
          setShow2(false);
        }, 1500);
        setbtndis1(false);
        dispatch(setAccountVerification({}));
      } else if (AccountVerification.output === "Invalid Email Id") {
        setShow2(true);
        setModalMsg("Invalid Email Id");
        setTimeout(() => {
          setShow2(false);
        }, 1500);
        setbtndis1(false);
        dispatch(setAccountVerification({}));
      } else if (AccountVerification.output === "Email Not Found") {
        setShow2(true);
        setModalMsg("Email Not Found");
        setTimeout(() => {
          setShow2(false);
        }, 1500);
        setbtndis1(false);
        dispatch(setAccountVerification({}));
      }
    }
  }, [AccountVerification]);

  // ------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    <>
      {spinner ? (
        <Loader spin="secure_data" />
      ) : (
        <>
          <Div
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            width="100%"
            background=" rgba(240, 244, 247, 1)"
          >
            <Container>
              <Title
                fontSize="36px"
                fontWeight="700"
                fontFamily="opensans"
                lineHeight="44px"
                marginBottom="16px"
              >
                Verify your Account
              </Title>
              <Text
                fontSize="16px"
                fontWeight="400"
                fontFamily="opensans"
                lineHeight="30px"
                color="rgba(102, 112, 133, 1)"
                marginBottom="24px"
              >
                Check your email and enter the verification code provided
              </Text>

              <Form>
                <FormGroupInline ml="auto" mr="auto">
                  {Otp.map((row, i) => {
                    return (
                      <Div display="inline-grid" width="25%" key={i}>
                        <Input
                          key={i}
                          width="80px"
                          height="80px"
                          fontSize="36px"
                          lineHeight="44px"
                          fontWeight="700"
                          fontFamily="'Urbanist'"
                          maxLength="1"
                          textAlign="center"
                          maxlength="1"
                          value={row}
                          onChange={(e) => HandleOtp(e.target, i)}
                          onFocus={(e) => e.target.select()}
                          onKeyUp={(e) => KeyDown(e, i)}
                          onPaste={(e) => FieldPaste(e, i)}
                        />
                      </Div>
                    );
                  })}
                  {showTimer ? (
                    <>
                      {otpErr ? (
                        <Div
                          display="inline-grid"
                          width="100%"
                          gridColumn="1/5"
                        >
                          <FormLabel
                            marginBottom="32px"
                            textAlign="center"
                            fontSize="12px"
                            color="rgba(226, 61, 40, 1)"
                            fontWeight="400"
                            lineHeight="16.34px"
                            fontFamily="opensans,sans-serif"
                          >
                            OTP is invalid
                          </FormLabel>
                        </Div>
                      ) : (
                        <Div
                          display="inline-grid"
                          width="100%"
                          gridColumn="1/5"
                        >
                          <FormLabel
                            marginBottom="32px"
                            textAlign="left"
                            fontSize="12px"
                            color="rgba(102, 112, 133, 1)"
                            fontWeight="400"
                            lineHeight="16.34px"
                            fontFamily="opensans,sans-serif"
                          >
                            OTP valid time - {Math.floor(timer / 60)}:
                            {(timer % 60).toString().padStart(2, "0")} mins
                          </FormLabel>
                        </Div>
                      )}
                    </>
                  ) : (
                    <Div display="inline-grid" width="100%" gridColumn="1/5">
                      <FormLabel
                        marginBottom="32px"
                        textAlign="left"
                        fontSize="12px"
                        color="rgba(226, 61, 40, 1)"
                        fontWeight="400"
                        lineHeight="16.34px"
                        fontFamily="opensans,sans-serif"
                      >
                        OTP time has expired. Generate a new code.
                      </FormLabel>
                    </Div>
                  )}
                </FormGroupInline>

                <Button
                  width="400px"
                  height="48px"
                  background="#2C7BE5"
                  hoverBackColor="rgba(64, 123, 255, 1)"
                  fontWeight="600"
                  fontSize="16px"
                  lineHeight="22px"
                  color="rgba(255, 255, 255, 1)"
                  fontFamily="opensans,sans-serif"
                  disabled={btndis}
                  onClick={(e) => HandleVerify(e)}
                >
                  Verify Account
                </Button>
              </Form>
              <Text
                textAlign="center"
                marginTop="16px"
                fontSize="18px"
                fontWeight="400"
                lineHeight="26px"
                color="rgba(102, 112, 133, 1)"
                fontFamily="Urbanist"
              >
                Don’t receive verification code?
                <ResendButton
                  disabled={btndis1}
                  cursor={btndis1 ? "not-allowed" : "pointer"}
                  onClick={HandleResend}
                  fontSize="18px"
                >
                  &nbsp;Resend Code
                </ResendButton>
              </Text>
            </Container>
          </Div>
          <MessageModal
            active={show1}
            color="success"
            onHide={() => setShow1(false)}
          >
            {modalMsg}
          </MessageModal>
          <MessageModal
            active={show2}
            color="danger"
            onHide={() => setShow2(false)}
          >
            {modalMsg}
          </MessageModal>
        </>
      )}
    </>
  );
};

export default EmailVerification;
