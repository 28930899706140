import { useEffect, useState } from "react";
import moment from "moment";
import axiosInstance from "./axios/axios";
import { Encrypt_Value, Decrypt_Value } from "./MiddleWare/EncryptDecrypt";
const useLogout = (startTime) => {
  const [timer, setTimer] = useState(startTime);
  const [token_exp_times, settoken_exp_times] = useState(
    moment(
      localStorage.getItem("login_time")
        ? localStorage.getItem("login_time")
        : ""
    ).format("YYYY-MM-DD HH:mm A")
  );

  /////////////////////////////////////getting refresh token before session ends starts here/////////////////////////////////////////
  const refreshToken = () => {
    var client_id = process.env.REACT_APP_BACKEND_CLIENT_ID;
    var refresh_token = localStorage.getItem("refresh_token");

    var details = {
      grant_type: "refresh_token",
      client_id: client_id,
      refresh_token: refresh_token,
    };
    let Encrypt_details = Encrypt_Value(details, "vozo");
    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    axiosInstance({
      method: "post",
      url: "/vozo/refresh",
      data: Encrypt_details,
    })
      .then((response) => {
        var Decrpt_response = Decrypt_Value(response.data, "vozo");
        localStorage.setItem("token", Decrpt_response.token);
        localStorage.setItem("refresh_token", Decrpt_response.refresh_token);

        // axiosInstance.defaults.headers.common["Authorization"] =
        //   Decrpt_response.token;

        localStorage.setItem("login_time", Decrpt_response.token_exp);

        settoken_exp_times(
          moment(Decrpt_response.token_exp).format("YYYY-MM-DD HH:mm A")
        );
      })
      .catch((error) => {
        console.log(error);
        localStorage.clear();
        window.location.href = "/";
      });
  };
  useEffect(() => {
    const CurrTimes = moment().add(3, "m").format("YYYY-MM-DD HH:mm A");
    if (token_exp_times === CurrTimes) {
      refreshToken();
    }
    /////////////////////////////////////getting refresh token before session ends ends here/////////////////////////////////////////

    const myInterval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 60000); // Interval duration to 60000 milliseconds (60 second = 1 min)
    const resetTimeout = () => {
      setTimer(startTime);
    };

    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    return () => {
      clearInterval(myInterval);
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
      }
    };
  });

  return timer;
};

export default useLogout;
