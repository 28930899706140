import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  ModalHeader,
  ModalBody,
  ModalDialog,
  ModalContainer,
} from "../../../components/setting/PatientPortalSettings/StyledComponents";
import {
  Div,
  Button,
  Modal,
  Link,
  BillingBlock,
} from "../../../StyledComponents";
import { BsArrowLeft } from "react-icons/bs";
import { Input } from "../../../components/setting/Inventory/styles";
import { FiMail } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { Decrypt_Value } from "../../../MiddleWare/EncryptDecrypt";
import MessageModal from "../../../components/login/ForgotPassword/MessageModal";
import {
  getAccountVerification,
  setTemporaryEmail,
  setAccountVerification,
} from "../../../StateManagement/Reducers/SubscriptionRenewalState";

function AccountVerification() {
  //-------------------------------------------State and Variables Start-------------------------------------------------------- //

  const dispatch = useDispatch();
  let history = useHistory();

  const [email_id, setemailid] = useState("");
  const [mailError, setMailError] = useState(false);
  const [mailErrMsg, setMailErrMsg] = useState("");
  const [btndis, setbtndis] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [modalMsg, setModalMsg] = useState("");

  const AccountVerification = useSelector(
    (state) => state.SubsRenewal?.AccountVerification
  );
  const Reactiverecover = useSelector(
    (state) => state.SubsRenewal?.ReactiveRecover
  );

  var user_id = Decrypt_Value(localStorage.getItem("user_id"), "vozo");
  //-------------------------------------------State and Variables End-------------------------------------------------------- //

  // ------------------------------- Functions Start--------------------------------------------------------- //

  const handleSave = () => {
    if (email_id === "") {
      setMailError(true);
      setMailErrMsg("Enter Email address");
      return;
    }
    if (mailError) {
      setMailErrMsg("Enter valid e-mail address");
      return;
    }
    let article = {
      email: email_id,
      user_id: user_id,
      type: Reactiverecover,
    };
    dispatch(getAccountVerification(article));
    dispatch(setTemporaryEmail({ email_id }));
    setbtndis(true);
  };

  const backToLogin = () => {
    localStorage.clear();
    localStorage.setItem("logout", true);
    window.location.href = "/";
  };

  const EmailCheck = (e) => {
    setemailid(e.target.value);

    const regex = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[a-zA-Z]{1,4}$/;
    if (regex.test(e.target.value)) {
      setMailError(false);
    } else {
      setMailError(true);
      setMailErrMsg("Enter valid e-mail address");
    }
  };

  // ------------------------------- Functions End--------------------------------------------------------- //

  // ------------------------------- UseEffect Start ------------------------------------------------------- //

  useEffect(() => {
    let counts = 3 - parseInt(AccountVerification.count);
    if (AccountVerification.status === "Success") {
      setShow1(true);
      if (Reactiverecover === "reactive_acct") {
        setModalMsg("Mail sent Successfully");
      } else {
        setModalMsg(
          "Mail sent Successfully you have only " + counts + " attempts"
        );
      }
      setTimeout(() => {
        setShow1(false);
        history.push("/otpverification");
      }, 2000);

      setbtndis(false);
      dispatch(setAccountVerification({}));
    } else if (AccountVerification.status === "Failure") {
      if (AccountVerification.output === "Email sent failed") {
        setShow2(true);
        setModalMsg("Mail not sent  Successfully");
        setTimeout(() => {
          setShow2(false);
        }, 1500);
        setbtndis(false);
        dispatch(setAccountVerification({}));
      } else if (AccountVerification.output === "Invalid Email Id") {
        setShow2(true);
        setModalMsg("Invalid Email Id");
        setTimeout(() => {
          setShow2(false);
        }, 1500);
        setbtndis(false);
        dispatch(setAccountVerification({}));
      } else if (AccountVerification.output === "Email Not Found") {
        setShow2(true);
        setModalMsg("Email Not Found");
        setTimeout(() => {
          setShow2(false);
        }, 1500);
        setbtndis(false);
        dispatch(setAccountVerification({}));
      }
    } else if (AccountVerification.status === "reached maximum attempts") {
      setShow2(true);
      setModalMsg("You have reached maximum attempts");
      setTimeout(() => {
        setShow2(false);
      }, 1500);
      setbtndis(false);
      dispatch(setAccountVerification({}));
    }
  }, [AccountVerification]);

  // ------------------------------- UseEffect End ------------------------------------------------------- //

  return (
    <div>
      <Modal
        show={true}
        justifyContent="center"
        width="100%"
        height="100%"
        backcolor="unset"
      >
        <ModalContainer
          position="relative"
          justifyContent="center"
          borderRadius="8px"
        >
          <ModalDialog width="483px" borderRadius="8px">
            <ModalHeader
              padding="40px 0 0 0px"
              justifyContent="center"
              borderLess
              fontSize="24px"
              fontWeight="400"
              fontFamily="Open Sans, sans-serif"
              color="#2D3748"
            >
              {Reactiverecover === "reactive_acct"
                ? "Account Verification"
                : "Recover your Data"}
            </ModalHeader>

            <ModalBody padding="20px 40px 40px 40px">
              <Div
                fontSize="16px"
                fontWeight="400"
                fontFamily="Open Sans, sans-serif"
                color="#718096"
                lineHeight="21.79px"
                textAlign="center"
              >
                {Reactiverecover === "reactive_acct"
                  ? "Enter a valid email which is associated with Qiaben EHR"
                  : "Make sure you enter the correct details for the recovery of required data"}
              </Div>
              <Div
                padding="25px 0px 0px 0px"
                textAlign="left"
                fontSize="16px"
                fontWeight="400"
                fontFamily="Open Sans, sans-serif"
                color="#7D8592"
              >
                Email Address
                <Div position="relative">
                  <Div
                    position="absolute"
                    top="50%"
                    left="18px"
                    transform="translateY(-50%)"
                    color="#718096"
                  >
                    <FiMail />
                  </Div>
                  <Input
                    placeholder="Enter your email"
                    display="flex"
                    width="100%"
                    height="44px"
                    background="#FFFFFF"
                    fontSize="14px"
                    fontWeight="400"
                    fontFamily="'Open Sans',sans-serif"
                    border="1px solid #D8E0F0"
                    borderRadius="4px"
                    color="#718096"
                    pl="10px"
                    paddingTop="8px !important"
                    padding="0px 18px 0px 40px"
                    margin="7px 0px 0px 0px"
                    value={email_id}
                    style={
                      mailError
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : {}
                    }
                    onChange={EmailCheck}
                  ></Input>
                </Div>
                {mailError ? <p className="errormessage">{mailErrMsg}</p> : ""}
              </Div>
              <Button
                width="100%"
                height="48px"
                background="#3F8CFF"
                color="#FFFFFF"
                fontSize="16px"
                fontFamily="'Open Sans',sans-serif"
                fontWeight="700"
                margin="24px 0px 25px 0px"
                cursor="pointer"
                borderRadius="4px"
                className="save_active"
                disabled={btndis}
                onClick={handleSave}
              >
                {Reactiverecover === "reactive_acct"
                  ? "Get verification code"
                  : "Recover Data"}
              </Button>
              <Link onClick={backToLogin}>
                <Div flexCenterAll color="#2C7BE5 !important" fontSize="16px">
                  <BillingBlock
                    marginRight="8px"
                    marginLeft="unset"
                    color="#2C7BE5 !important"
                  >
                    <BsArrowLeft />
                  </BillingBlock>
                  <BillingBlock color="#2C7BE5 !important" marginLeft="unset">
                    Back to Login
                  </BillingBlock>
                </Div>
              </Link>
            </ModalBody>
          </ModalDialog>
          <MessageModal
            active={show1}
            color="success"
            onHide={() => setShow1(false)}
          >
            {modalMsg}
          </MessageModal>
          <MessageModal
            active={show2}
            color="danger"
            onHide={() => setShow2(false)}
          >
            {modalMsg}
          </MessageModal>
        </ModalContainer>
      </Modal>
    </div>
  );
}

export default AccountVerification;
