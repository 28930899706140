import { createSlice } from "@reduxjs/toolkit";

export const SettingSlice = createSlice({
  name: "Setting",
  initialState: {},
  ManagementDrugs: {},
  DestroyedDrugs: {},
  EditDrugs: {},
  CPT4CodesData: {},
  idleTimer: "",
  reducers: {
    AddDrugData: (Data) => {
      return Data;
    },
    DestroyDrugData: (Data) => {
      return Data;
    },
    GetManagementDrugs: (Data) => {
      return Data;
    },
    SetManagementDrugs: (state, action) => {
      state.ManagementDrugs = action.payload;
    },
    GetCPT4Codes: (Data) => {
      return Data;
    },
    SetCPT4Codes: (state, action) => {
      state.CPT4CodesData = action.payload;
    },
    GetEditDrugs: (Data) => {
      return Data;
    },
    SetEditDrugs: (state, action) => {
      state.EditDrugs = action.payload;
    },
    GetDestroyedDrugs: (Data) => {
      return Data;
    },
    SetDestroyedDrugs: (state, action) => {
      state.DestroyedDrugs = action.payload;
    },
    setIdleTimer: (state, action) => {
      state.idleTimer = action.payload;
    },
  },
});

export const {
  AddDrugData,
  DestroyDrugData,
  GetManagementDrugs,
  SetManagementDrugs,
  GetEditDrugs,
  SetEditDrugs,
  GetCPT4Codes,
  SetCPT4Codes,
  GetDestroyedDrugs,
  SetDestroyedDrugs,
  setIdleTimer,
} = SettingSlice.actions;

export default SettingSlice.reducer;
