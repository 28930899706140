import React, { useState, useEffect } from "react";
import { Div } from "../../patient/styles";
import axiosInstance from "../../../axios/axios";
import {
  BoxContainer,
  InputBox,
  MyButton,
  CopyButton,
  MyLabel,
  Output,
  Div1,
} from "./Styles";
import SideNav from "../../Sidenav";
import { useSelector } from "react-redux";

var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

export const Encrypt_Value = (Val, key) => {
  return CryptoJS.AES.encrypt(JSON.stringify(Val), key, {
    format: Encode_Decode_JSON,
  }).toString();
};

export const Decrypt_Value = (data, key) => {
  return CryptoJS.AES.decrypt(data, key, {
    format: Encode_Decode_JSON,
  }).toString(CryptoJS.enc.Utf8);
};

const EncryptDecryptComp = () => {
  const [encryptData, setEncyptData] = useState("");
  const [encryptDataRes, setEncyptDataRes] = useState("");

  const [decryptData, setDecryptData] = useState("");
  const [decryptDataRes, setDecryptDataRes] = useState("");
  var NavData1 = useSelector((state) => state.Schedule?.NavData);

  const [navData1, setNavData1] = useState("");

  const EncryptData = () => {
    setEncyptDataRes("");
    let obj = [];
    if (encryptData.includes("=")) {
      obj = encryptData;
    } else {
      obj = JSON.parse(encryptData);
    }

    let data = Encrypt_Value(obj, "vozo");
    setEncyptDataRes(data);
    console.log(JSON.stringify(data));
  };
  const DecryptData = () => {
    // setDecryptDataRes("");
    // let data = Decrypt_Value(decryptData, "vozo");
    // setDecryptDataRes(JSON.stringify(data));
    // console.log(data);

    let data = decryptData;
    axiosInstance
      .post("/vozo/Decrypt/Modify", data)

      .then((response) => {
        // var d2 = Decrypt_Value(response.data, "vozo");
        setDecryptDataRes(JSON.stringify(response.data));
        console.log(JSON.stringify(response.data));
      });
  };
  const ClearDecryptData = () => {
    setDecryptDataRes("");
    setDecryptData("");
  };
  const ClearEncryptData = () => {
    setEncyptDataRes("");
    setEncyptData("");
  };
  useEffect(() => {
    setNavData1(NavData1);
  }, [NavData1]);
  return (
    <>
      <>
        <SideNav />
      </>

      <Div
        id="demographics"
        className={
          (navData1 === "iconopen" &&
            "appt-block-main-bill schedule-right-nav p-r-13 m-r-0") ||
          (navData1 === "iconclose" &&
            "appt-block-main-bill schedule-right-nav1 p-r-13 m-r-0")
        }
      >
        <div className="appt-block-main schedule-comp1 m-b-0 m-t-4">
          <BoxContainer>
            <MyLabel>Encrypt Data</MyLabel>
            <Div1>
              <InputBox
                type="text-area"
                value={encryptData}
                onChange={(e) => setEncyptData(e.target.value)}
              />
              <Output>Output</Output>
              <InputBox
                type="text-area"
                value={encryptDataRes}
                disabled={true}
              />
            </Div1>
            <Div1 style={{ justifyContent: "space-between" }}>
              <Div1>
                <MyButton onClick={() => EncryptData()}>Encrypt</MyButton>
                <MyButton onClick={() => ClearEncryptData()}>Clear</MyButton>
              </Div1>

              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(encryptDataRes);
                }}
              >
                Copy
              </CopyButton>
            </Div1>
          </BoxContainer>
          <BoxContainer style={{ height: "300px" }}>
            <MyLabel>Decrypt Data</MyLabel>
            <Div1>
              <InputBox
                value={decryptData}
                onChange={(e) => setDecryptData(e.target.value)}
              />
              <Output>Output</Output>

              <InputBox
                type="text-area"
                value={decryptDataRes}
                disabled={true}
              />
            </Div1>
            <Div1 style={{ justifyContent: "space-between" }}>
              <Div1>
                <MyButton onClick={() => DecryptData()}>Decrypt</MyButton>
                <MyButton onClick={() => ClearDecryptData()}>Clear</MyButton>
              </Div1>

              <CopyButton
                onClick={() => {
                  navigator.clipboard.writeText(decryptDataRes);
                }}
              >
                Copy
              </CopyButton>
            </Div1>
          </BoxContainer>
        </div>
      </Div>
    </>
  );
};

export default EncryptDecryptComp;
