import styled from "styled-components";
import { TableRow } from "../setting/PatientPortalSettings/StyledComponents";

export const NoteButton = styled.button`
  border: none;
  background: ${({ active }) => (active ? "#2C7BE5" : "#ededed")};
  border-radius: 2px;
  padding: 4px 8px;
  color: ${({ active }) => (active ? "#FFFFFF" : "#718096")};
  font-family: "Inter";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin: 0px 2px;
  height: 26px;
`;

export const NoteLabel = styled.label`
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #2d3748;
  margin-right: 32px;
  margin-top: 4px;
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justify }) => justify && justify};
  align-items: center;
  margin-bottom: ${({ marginButtom }) => marginButtom && "20px"};
`;
export const Tablerow = styled(TableRow)`
  border: ${({ border }) => border && border};
  text-align: ${({ textAlign }) => textAlign && textAlign};
  background-color: ${(p) => p.background && p.background};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};

  display: ${({ display }) => (display ? display : "table-row")};
  & > th,
  & > td {
    color: ${({ color }) => color && color};
    font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
    font-family: ${({ fontFamily }) => fontFamily && fontFamily};
    line-height: ${(p) => (p.lineHeight ? p.lineHeight : p.lineHeight)};
    font-size: ${({ fontSize }) => fontSize && fontSize};
    padding: ${({ padding }) => padding && padding};
    padding-top: ${(p) => p.paddingTop && p.paddingTop};
    padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
    border-bottom: ${(p) => p.borderBottom && p.borderBottom};
    background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  }
  & > td.brdr_none_styled {
    border-bottom: none;
  }
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  &.table_last_child tr:last-child {
    border-bottom: "none !important";
  }
  &:last-child {
    border-bottom: ${(p) => p.lastchild && "none"};
  }
`;

export const Tablerow1 = styled(TableRow)`
  border: ${({ border }) => border && border};
  display: ${({ display }) => (display ? display : "table-row")};
  & > th {
    color: ${({ color }) => color && color};
    font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
    font-family: ${({ fontFamily }) => fontFamily && fontFamily};

    padding: ${({ padding }) => padding && padding};
    border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  }
  & > td:first-child {
    padding: "15px 0 15px 20px";
    color: "#2e2e2e !important";
    font-weight: "800" !important;
  }
`;
export const PageTitle = styled.h1`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight || "700"};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  display: flex;
  align-items: ${(p) => (p.alignItem ? p.alignItem : "center")};
  color: ${(p) => p.color && p.color};
  padding: ${(p) => p.padding && p.padding};
`;

export const Button = styled.button`
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  color: ${(p) => p.color && p.color};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  position: ${(p) => p.position && p.position};
  padding: ${(p) => p.padding && p.padding};
  ${(p) =>
    p.lock &&
    `svg {
     margin-top:-3px;
     margin-right:1px;
    }
    `}

  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: rgba(44, 123, 229, 0.5);
    cursor: not-allowed;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1);
  }
  &.close_active:disabled {
    color: rgba(44, 123, 229, 0.5);
    background-color: #fff;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
  &:active {
    background-color: ${(p) => p.ActiveBackColor && p.ActiveBackColor};
    border: ${(p) => p.border && p.border};
  }
`;

export const Div = styled.div`
  display: ${(p) => p.display && p.display};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  flex-wrap: ${(p) => p.flexWrap && p.flexWrap};
  position: ${(p) => p.position && p.position};
  color: ${(p) => p.color && p.color};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
`;
export const Span = styled.span`
  display: ${(p) => p.display && p.display};
  margin: ${(p) => p.margin && p.margin};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-size: ${(p) => p.fontSize && p.fontSize};
  color: ${(p) => p.color && p.color};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
`;

export const Imagecalendar = styled.img`
  position: absolute;
  top: ${(p) => (p.top ? p.top : "20px")};
  right: ${(p) => (p.right ? p.right : "163px")};
  width: 16px;
  height: 16px;
  object-fit: contain;
  cursor: pointer;
`;
export const RowDiv = styled(Div)`
  display: ${({ inline }) => (inline ? "inline-flex" : "flex")};
  margin-top: ${({ mt }) => (mt ? mt : "16px")};

  &.sign-up-mail-row {
    position: relative;
  }
  &.sign-up-mail-row svg {
    position: absolute;
    top: 40px;
    left: 12px;
    color: #718096;
    width: 20px;
    height: 20px;
  }
`;
export const FormLabel = styled.label`
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  color: ${(p) => p.color && p.color};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  cursor: ${(p) => p.cursor && p.cursor};
  text-align: ${(p) => p.textAlign && p.textAlign};
  display: ${(p) => p.display && p.display};
  padding: ${(p) => p.padding && p.padding};
`;

export const SpanTag = styled.span`
  gap: ${(p) => p.gap && p.gap};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  cursor: ${(p) => p.cursor && p.cursor};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  color: ${(p) => p.color && p.color};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overFlow && p.overFlow};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : p.lineHeight)};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  position: ${(p) => p.position && p.position};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  background-position: ${(p) => p.backgroundPosition && p.backgroundPosition};
  background-repeat: ${(p) => p.backgroundRepeat && p.backgroundRepeat};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  float: ${(p) => p.float && p.float};
  margin: ${(p) => p.margin && p.margin};
  & > svg {
    height: ${(props) => props.height};
    width: ${(props) => props.width};
  }
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};

  ${({ bullet }) =>
    bullet &&
    `
      display: inline-block;
      margin-right: 4px;
      border-radius: 50%;      
      background-color: #D8E0F0;
      height: 8px;
      width: 8px;
    `}
`;
export const Input = styled.input.attrs((props) => ({
  readOnly: props.readOnly && "readonly",
  autoComplete: props.autoComplete && "off",
}))`
  color: ${(p) => p.color && p.color};
  font-style: ${(p) => p.fontStyle && p.fontStyle};
  margin: ${(p) => p.margin && p.margin};
  display: ${(p) => p.display && p.display};
  text-align: ${(p) => p.textAlign && p.textAlign};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-left: ${(p) => p.borderLeft && p.borderLeft};
  border-top-left-radius: ${(p) => p.btlr && p.btlr};
  border-top-right-radius: ${(p) => p.btrr && p.btrr};
  border-bottom-left-radius: ${(p) => p.bblr && p.bblr};
  border-bottom-right-radius: ${(p) => p.bbrr && p.bbrr};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  height: ${(p) => p.height && p.height};
  padding: ${(p) => p.padding && p.padding};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  &:focus {
    outline: none;
  }
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  cursor: ${(p) => p.cursor && p.cursor};
  /* min-width: 135px; */
  &.hide-icon::-webkit-search-cancel-button,
  .hide-icon::-webkit-search-decoration,
  .hide-icon::-webkit-search-results-button,
  .hide-icon::-webkit-search-results-decoration {
    display: none;
  }
  &::placeholder {
    color: ${(p) => p.pColor && p.pColor};
  }
`;
