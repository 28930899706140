import styled from "styled-components";

export const UserContainer = styled.div`
  overflow-y: scroll;
  height: calc(115% - 123px);
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const UserContent = styled.div`
  display: flex;
  padding: 12px 25.36px 14px 17px;
  padding: ${({ active }) =>
    active ? "12px 12.36px 14px 16px" : "12px 12.36px 14px 20px"};
  height: 56px;
  width: 93%;
  border-left: ${({ active }) => active && "4px solid #407bff"};
  // background: ${({ active }) => active && "rgba(64, 123, 255, 0.1)"};
  background: ${({ active }) => (active ? "rgba(64, 123, 255, 0.1)" : "#fff")};
  border-radius: 4px;
  cursor: pointer;
  margin: 10px 0px;
  margin-top: ${(p) => p.marginTop && p.marginTop};
  &:hover {
    background: rgba(64, 123, 255, 0.1);
  }
`;

export const ChatSession = styled.div`
  width: 100%;
  background: #f4f4f4;
  ${({ empty }) =>
    empty &&
    `
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  `}
`;
