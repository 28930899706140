import React, { useState, memo } from "react";
import Warning from "../popups/Warning";

const UpgradeRequired = ({
  title,
  description,
  boxContent,
  handlecloseUpgrade,
  bgChange,
}) => {
  const [showWarning, setShowWarning] = useState(true);
  const handleShow = () => {
    setShowWarning(false);
    if (typeof handlecloseUpgrade === "function") handlecloseUpgrade();
  };

  return (
    <div className="right-block m-l-250">
      <div id="demographics" className="vozo_padding">
        <Warning
          show={showWarning}
          onHide={handleShow}
          title={title}
          description={description}
          boxContent={boxContent}
          bgchange={bgChange && bgChange}
        />
      </div>
    </div>
  );
};

export default memo(UpgradeRequired);
