import React, { memo } from "react";
import { Modal, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const UserGuide = ({ close, ProviderName }) => {
  // ------------------------------ State and Variables Start----------------------------------------- //

  const history = useHistory();

  // ------------------------------ State and Variables End----------------------------------------- //

  // ------------------------------ Functions Start--------------------------------------------------- //

  const forexploreclient = () => {
    close();
    history.push({
      pathname: `/patient/nav/1`,
      data: { steponeclient: true },
    });
  };

  const forexploreteleapp = () => {
    close();
    localStorage.setItem("teleapppopup", "true");
    history.push({
      pathname: `/schedule/nav/1`,
      data: { normalapppopup: true },
    });
  };

  const forexploreprofilepopup = () => {
    close();
    history.push({
      pathname: `/settings/nav/1`,
      data: { profpopupstarted: true },
    });
  };

  const forexplorebillservice = () => {
    close();
    history.push({
      pathname: `/settings/settingservice`,
      data: { serbillgetstart: true },
    });
  };

  const forexplorefacistaff = () => {
    close();
    history.push({
      pathname: `/settings/settingpracticeinfo`,
      data: { facilitystaffstarted: true },
    });
  };

  // ------------------------------ Functions End--------------------------------------------------- //

  return (
    <div id="user_guide_page">
      <Form onSubmit={(e) => e.preventDefault()}>
        <Modal.Header closeButton>
          <div className="user-gui-title">
            <span className="user-guide-color">{ProviderName}</span>
            <span>’s Get Started Guide </span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="user-gui-title-small">
            Here is the most comprehensive guide to answering all of your
            questions about our EHR.
          </div>

          <div className="user-guide-list-1">
            <span className="for-user-guide-list-data">1</span>

            <div className="block-display">
              <div
                className="for-user-guide-list-data-1"
                onClick={() => forexploreclient()}
              >
                Explore a Sample Patient
              </div>
              <div className="for-user-guide-list-data-2">
                Explore a Sample Patient
              </div>
            </div>
          </div>

          <div className="user-guide-list-2">
            <span className="for-user-guide-list-data">2</span>

            <div className="block-display">
              <div
                className="for-user-guide-list-data-1"
                onClick={() => forexploreteleapp()}
              >
                Create In-Office or Telehealth Appointments
              </div>
              <div className="for-user-guide-list-data-2">
                Set the date, time, service and select your appointment
                location.
              </div>
            </div>
          </div>

          <div className="user-guide-list-2">
            <span className="for-user-guide-list-data">3</span>

            <div className="block-display">
              <div
                className="for-user-guide-list-data-1"
                onClick={() => forexploreprofilepopup()}
              >
                Edit your Profile Settings
              </div>
              <div className="for-user-guide-list-data-2">
                Add your NPI and set up your office location.
              </div>
            </div>
          </div>

          <div className="user-guide-list-2">
            <span className="for-user-guide-list-data">4</span>

            <div className="block-display">
              <div
                className="for-user-guide-list-data-1"
                onClick={() => forexplorebillservice()}
              >
                Edit Billing Settings &amp; Services
              </div>
              <div className="for-user-guide-list-data-2">
                Add your tax ID and set your practice services &amp; pricing.
              </div>
            </div>
          </div>

          <div className="user-guide-list-7">
            <span className="for-user-guide-list-data">5</span>

            <div className="block-display">
              <div
                className="for-user-guide-list-data-1"
                onClick={() => forexplorefacistaff()}
              >
                Add Facility And Staffs
              </div>
              <div className="for-user-guide-list-data-2">
                Accept credit cards and store them for future billing.
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <>
            <div className="user-gui-title-footer">
              <span>You can </span>
              <span className="user-guide-style-1">
                <a
                  href="https://vozohealth.com/ehr-training"
                  target="_blank"
                  rel="noreferrer"
                >
                  Watch Helpful Videos
                </a>
              </span>
              <span>or </span>
              <span className="user-guide-style-2">
                <a
                  href="https://vozohealth.com/support"
                  target="_blank"
                  rel="noreferrer"
                >
                  Read Detailed Guides.
                </a>
              </span>
            </div>
          </>
        </Modal.Footer>
      </Form>
    </div>
  );
};

export default memo(UserGuide);
