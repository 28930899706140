import React, { useState, useEffect, memo } from "react";
import { Form, Button, Row, Col, Modal } from "react-bootstrap";
import axiosInstance from "../../axios/axios";
import { IoCloseSharp } from "react-icons/io5";
import { BsCheckCircle } from "react-icons/bs";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Input } from "antd";
var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (Val, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(Val, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};

const PasswordExpired = ({ close }) => {
  const [pass, setPass] = useState("");
  const [current, setCurrent] = useState("");
  const [confirm, setConfirm] = useState("");
  const [passErr, setPassErr] = useState(false);
  const [empPass, setEmppass] = useState(false);
  const [cpass, setcpass] = useState(false);
  const [lenErr, setLenErr] = useState(false);
  const [minimum, setMinimum] = useState("0");
  const [passStrong, setPassStrong] = useState("");
  const [strength, setStrength] = useState(false);
  const [date, setDate] = useState("");
  const [mon, setMonth] = useState("");
  const [years, setYear] = useState("");
  const [modalMsg, setModalMsg] = useState("");
  const [ModalAlerShow, setModalAlerShow] = useState(false);
  const [ModalAlerShow1, setModalAlerShow1] = useState(false);
  const [showpassword, setshowpassword] = useState(true);
  const [showpassword1, setshowpassword1] = useState(true);
  const [showpassword2, setshowpassword2] = useState(true);
  const [btnDis, setbtnDis] = useState(false);
  var userID = Decrypt_Value(localStorage.getItem("user_id"), "vozo");

  useEffect(() => {
    (async () => {
      await axiosInstance
        .get("/vozo/settingsecuritydata")
        .then((response) => {
          let data = Decrypt_Value(response.data, "vozo");
          setMinimum(data[0]["gl_value"]);
          setPassStrong(data[5]["gl_value"]);
        })
        .catch((e) => {
          setMinimum("0");
          setPassStrong("");
        });

      await axiosInstance
        .get("/vozo/user/password/date?uid=" + userID)
        .then((res) => {
          var future = new Date(res.data.date);
          var addition =
            Number(res.data.pass_exp_days) + Number(res.data.pass_grace_time);
          future.setDate(future.getDate() + addition);
          var date = future.getDate();
          var month = future.getMonth() + 1;
          var year = future.getFullYear();
          setDate(date);
          setMonth(month);
          setYear(year);
        })
        .catch((e) => {
          setDate("");
          setMonth("");
          setYear("");
        });
    })();
  }, []);

  const passExp =
    localStorage.getItem("exp") !== "null"
      ? Decrypt_Value(localStorage.getItem("exp"), "vozo")
      : "pass";

  const onSubmit = () => {
    const article = {
      password: pass,
      cpass: current,
    };
    // checking password strength
    var upper = 0,
      lower = 0,
      number = 0,
      special = 0;
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    for (var i = 0; i < pass.length; i++) {
      if (!isNaN(pass[i] * 1)) number++;
      else if (format.test(pass[i])) special++;
      else if (pass[i] === pass[i].toLowerCase()) lower++;
      else if (pass[i] === pass[i].toUpperCase()) upper++;
    }

    if (confirm === "" || confirm === null) {
      setcpass(true);
      return false;
    } else if (pass !== confirm) {
      setPassErr(true);
      return false;
    } else if (
      passStrong === "1" &&
      (upper === 0 || lower === 0 || number === 0 || special === 0)
    ) {
      setStrength(true);
      return false;
    } else if (current === "" || current === null) {
      setEmppass(true);
      return false;
    } else if (pass.length < minimum) {
      setLenErr(true);
      return false;
    } else {
      setbtnDis(true);
      axiosInstance
        .put("/vozo/update/provider/password?uid=" + userID, article)
        .then((res) => {
          var data = res.data;
          if (data.includes("Current user password not matched!")) {
            if (data.includes("Reuse of previous passwords not allowed!")) {
              setModalMsg("Reuse of previous passwords not allowed!");
              setModalAlerShow(true);
              setbtnDis(false);
              setTimeout(modalhide, 3000);
            } else {
              setModalMsg("Current user password not matched!");
              setModalAlerShow(true);
              setbtnDis(false);
              setTimeout(modalhide, 3000);
            }
          } else {
            setModalMsg(res.data);
            if (data === "Password Updated Successfully!") {
              setModalAlerShow1(true);
              setbtnDis(false);
              setTimeout(() => {
                setModalAlerShow1(false);
                close();
              }, 1500);
            } else {
              setModalAlerShow(true);
              setbtnDis(false);
              setTimeout(() => {
                setModalAlerShow(false);
              }, 1500);
            }
          }
        })
        .catch((err) => {
          console.error("there was an error", err);
        });
    }
  };

  const modalhide = () => {
    setModalAlerShow(false);
  };

  const passChange = (e) => {
    setPass(e.target.value);
    setLenErr(false);
    setPassErr(false);
    setStrength(false);
  };

  const currentChange = (e) => {
    setCurrent(e.target.value);
    setEmppass(false);
  };

  const confirmChange = (e) => {
    setConfirm(e.target.value);
    setLenErr(false);
    setPassErr(false);
    setStrength(false);
    setcpass(false);
  };
  //onClick

  const handlePassword = () => {
    setshowpassword((prevState) => !prevState);
  };

  const handlePassword1 = () => {
    setshowpassword1((prevState) => !prevState);
  };

  const handlePassword2 = () => {
    setshowpassword2((prevState) => !prevState);
  };

  return (
    <>
      <Modal.Body>
        <div className="pass-expire-box">
          {/* <div className='pass-exp-rotated-rect'></div> */}
          {/* <div className='pass-exp-rotated-rect' > <img src={rect} width="60px" height="60px" style={{borderRadius:10}} /> </div> */}
          {passExp === "grace_pass" ? (
            <div className="pass-expire-text">
              Your Password will expire on{" "}
              <span className="pas-exp-mod-color">{`${mon}/${date}/${years}`}</span>
            </div>
          ) : (
            <div className="pass-expire-text">
              Your Password was expired on{" "}
              <span className="pas-exp-mod-color">{`${mon}/${date}/${years}`}</span>
            </div>
          )}
          <div className="pass-exp-form">
            <Row>
              <Form.Group as={Col}>
                <Form.Label className="pass-exp-desc">
                  Current Password
                </Form.Label>
                <Input
                  style={
                    empPass
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                  value={current}
                  onChange={currentChange}
                  placeholder="Enter Current Password"
                  suffix={
                    showpassword ? (
                      <FaRegEye
                        onClick={handlePassword}
                        className="site-form-item-icon show_pass"
                      />
                    ) : (
                      <FaRegEyeSlash
                        onClick={handlePassword}
                        className="site-form-item-icon show_pass"
                      />
                    )
                  }
                  type={showpassword ? "password" : "text"}
                />
              </Form.Group>
              {empPass ? (
                <p className="errormessage">Enter valid current password</p>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className="pass-exp-desc">New Password</Form.Label>
                <Input
                  style={
                    passErr || lenErr || strength
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                  value={pass}
                  placeholder="Enter New Password"
                  onChange={passChange}
                  suffix={
                    showpassword1 ? (
                      <FaRegEye
                        onClick={handlePassword1}
                        className="site-form-item-icon show_pass"
                      />
                    ) : (
                      <FaRegEyeSlash
                        onClick={handlePassword1}
                        className="site-form-item-icon show_pass"
                      />
                    )
                  }
                  type={showpassword1 ? "password" : "text"}
                />
              </Form.Group>
              {passErr ? (
                <p className="errormessage">
                  Password and confirm password are mismatching
                </p>
              ) : (
                ""
              )}
              {lenErr ? (
                <p className="errormessage">
                  {`Minimum ${minimum} characters required`}{" "}
                </p>
              ) : (
                ""
              )}
              {strength ? (
                <p className="errormessage">
                  Should contain atleast one uppercase, lowercase, number and
                  special character{" "}
                </p>
              ) : (
                ""
              )}
            </Row>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className="pass-exp-desc">
                  Re-type New Password
                </Form.Label>
                <Input
                  value={confirm}
                  style={
                    passErr || cpass || lenErr || strength
                      ? { border: "1px solid red", borderRadius: "5px" }
                      : {}
                  }
                  placeholder="Re-type New Password"
                  onChange={confirmChange}
                  suffix={
                    showpassword2 ? (
                      <FaRegEye
                        onClick={handlePassword2}
                        className="site-form-item-icon show_pass"
                      />
                    ) : (
                      <FaRegEyeSlash
                        onClick={handlePassword2}
                        className="site-form-item-icon show_pass"
                      />
                    )
                  }
                  type={showpassword2 ? "password" : "text"}
                />
              </Form.Group>
              {passErr ? (
                <p className="errormessage">
                  Password and confirm password are mismatching
                </p>
              ) : (
                ""
              )}
              {cpass ? (
                <p className="errormessage">Enter valid Confirm password</p>
              ) : (
                ""
              )}
              {lenErr ? (
                <p className="errormessage">
                  {`Minimum ${minimum} characters required`}{" "}
                </p>
              ) : (
                ""
              )}
              {strength ? (
                <p className="errormessage">
                  Should contain atleast one uppercase, lowercase, number and
                  special character{" "}
                </p>
              ) : (
                ""
              )}
            </Row>
            <p className="spl-text">
              {" "}
              {`Requirements: Minimum ${minimum} characters.`}
              {passStrong === "1"
                ? "Should contain atleast one uppercase, lowercase, number and special character"
                : ""}{" "}
            </p>
            <div className="pass-exp-btn">
              {passExp === "grace_fail" ? (
                <></>
              ) : (
                <Button
                  variant="light"
                  className="close_active close_active2 pas-exp-can-btn"
                  onClick={() => close()}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="primary"
                className="save_active pas-exp-save-btn"
                disabled={btnDis}
                onClick={onSubmit}
              >
                Update Password
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal className="alert-popup-message" show={ModalAlerShow}>
        <div class="alert msg alert-danger" role="alert">
          <IoCloseSharp className="icons-bs" />
          {modalMsg}
        </div>
      </Modal>

      <Modal className="alert-popup-message" show={ModalAlerShow1}>
        <div class="alert msg alert-success" role="alert">
          <BsCheckCircle className="icons-bs" />
          {modalMsg}
        </div>
      </Modal>
    </>
  );
};
export default memo(PasswordExpired);
