import React from "react";
// import ReactDOM from "react-dom/client";
import { render } from "react-dom";

import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "semantic-ui-css/semantic.min.css";
import App from "./App";
import store from "./StateManagement/store";
import { Provider } from "react-redux";

// const root = ReactDOM.createRoot(document.getElementById("root"));

// root.render(
//   <Provider store={store}>
//     <Router>
//       <App />
//     </Router>
//   </Provider>,
//   document.getElementById("root")
// );

const root = document.getElementById("root");

render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  root
);
