import React, { memo, useEffect, useRef, useState } from "react";
import {
  Decrypt_Value,
  Encrypt_Value,
} from "../../../MiddleWare/EncryptDecrypt";
import webmFixDuration from "webm-duration-fix";
import { Image, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import pause_delete from "../../../assets/images/pause_delete.png";
import pause_record from "../../../assets/images/pause_record.png";
import on_record from "../../../assets/images/on_record.png";
import start_record from "../../../assets/images/start_record.png";
import accSetup_tick from "../../../assets/images/accSetup_tick.png";
import Draggable from "react-draggable";
// import "bootstrap/dist/css/bootstrap.min.css";
import axiosInstance from "../../../axios/axios";
import { useGlobalContext } from "./GlobalContext";
import CloseDoc from "../../../assets/images/CloseDoc.png";
import { Tooltip, TooltipText } from "../../top_navbar/Notifications/styles";

function UploadAudio() {
  //   const s3 = new AWS.S3();
  const {
    showGlobalComponent,
    setShowGlobalComponent,
    setGlobalAlert,
    setGlobalMsg,
    encounterID,
    provID,
    patID,
    eid,
  } = useGlobalContext();
  const history = useHistory();
  const audioStream = useRef(null);
  const mediaRecorder = useRef(null);
  const mediaRecorder1 = useRef(null);
  const audioStream1 = useRef(null);
  const audioChunks1 = useRef([]);
  const audioChunks = useRef([]);
  const audioContext1 = useRef(null);
  const audioContext = useRef(null);

  const [record, compRecord] = useState(false);
  const [recordingKey, setRecordingKey] = useState(0);
  const [audioURLs1, setAudioURLs1] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [three, setThree] = useState(false);
  const [two, setTwo] = useState(true);
  const [timer, setTimer] = useState(0);
  const [getUrls, SetUrls] = useState([]);
  const [audioURLs, setAudioURLs] = useState([]);
  const [isRecordingPaused, setIsRecordingPaused] = useState(false);
  const [summaries, setSummaries] = useState("");
  const [one, setOne] = useState(false);
  const [delClick, setDelClick] = useState(false);
  const [showTick, setShowTick] = useState(false);
  const user_id =
    localStorage.getItem("user_id") &&
    Decrypt_Value(localStorage.getItem("user_id"), "vozo");

  const formatDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const ampm = now.getHours() >= 12 ? "pm" : "am";

    return `${year}${month}${day}${hours}${minutes}${ampm}`;
  };

  const getFormattedFileName = (originalName) => {
    const formattedDateTime = formatDateTime();
    const fileName = originalName.split(".").slice(0, -1).join(".");
    const fileExtension = originalName.split(".").pop();
    return `conversation${formattedDateTime}.${fileExtension}`;
  };

  const handleOnSend = async (audioBlob) => {
    try {
      if (!record) {
        if (three && audioURLs.length === 0) {
          return;
        }

        if (delClick) {
          audioStream.current = null;
        }
        if (
          mediaRecorder.current &&
          (mediaRecorder.current.state === "recording" ||
            mediaRecorder.current.state === "paused")
        ) {
          mediaRecorder.current.stop();
          audioStream.current !== null &&
            audioStream.current.getTracks().forEach((track) => {
              track.stop();
            });
          audioStream.current = null;
          audioContext.current.close();
          setIsRecording(false);
          setIsRecordingPaused(false);
        }
      }

      const targetUrl = "/clinicalnotes";

      const timestamp = Date.now();
      const jobName = `Capminds_job_${timestamp}`;
      const fileName = getFormattedFileName("recording.mp3");

      let article = {
        jobName: jobName,
        bucketName: "testing-transcribe03",
        uploadedFile: fileName,
        patientID: patID,
        encounter: encounterID,
        userID: provID,
      };

      let enc = Encrypt_Value(article, "vozo");

      const filedata = new FormData();
      filedata.append("message", enc);
      let audioBlobModified = await processAudioBlob(audioBlob);
      if (audioBlobModified !== undefined && audioBlobModified.size > 0) {
        filedata.append(
          "audio[]",
          audioBlobModified,
          "conversation_recorded_audio.mp3"
        );
      }
      axiosInstance
        .post(
          "/vozo/healthscribe/insert/audioFiles?pid=" +
            localStorage.getItem("NewPid") +
            "&uid=" +
            user_id,
          filedata
        )
        .then((res) => {
          if (res.data === "success") {
            setSummaries("Saved");
            setTimeout(() => {
              setGlobalMsg(
                "Your audio has been saved! You can review it anytime in the audio file section."
              );
              setGlobalAlert(true);
              setShowGlobalComponent(false);
              // setOpenRecordModal(false);
            }, 1);

            setTimeout(() => {
              setShowTick(true);
              setGlobalMsg("");
              setGlobalAlert(false);
              console.log(patID, encounterID);
              if (window.location.pathname !== targetUrl) {
                localStorage.setItem("notesback", true);
                localStorage.setItem("place", "others");
                localStorage.setItem("NewPid", patID);
                localStorage.setItem("NewEid", eid);
                localStorage.setItem("NewEncounter", encounterID);
                history.push("/clinicalnotes");
              }
            }, 2000);
          }
        })
        .catch((err) => console.log(err.message));
    } catch (err) {
      console.log(err.message);
    }
  };

  const startRecording = async () => {
    try {
      compRecord(false);
      if (!audioStream.current) {
        audioStream.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      }
      audioChunks.current = [];
      audioContext.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(audioStream.current);
      setRecordingKey((prevKey) => prevKey + 1);
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };
      const audioBlobs = [];
      mediaRecorder.current.onstop = async () => {
        const duration = Date.now();
        const audioBlob = new Blob(audioChunks.current, { type: "audio/wav" });
        audioBlobs.push(audioBlob);
        const fixedBlob = await webmFixDuration(audioBlob, duration);
        setAudioURLs1(URL.createObjectURL(audioBlob));

        audioChunks.current = [];
        handleOnSend(fixedBlob);
      };

      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const startRecording1 = async () => {
    try {
      if (!audioStream1.current) {
        audioStream1.current = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
      }

      audioContext1.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      // const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder1.current = new MediaRecorder(audioStream1.current);

      mediaRecorder1.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks1.current.push(event.data);
        }
      };

      mediaRecorder1.current.onstop = () => {
        const audioBlob = new Blob(audioChunks1.current, { type: "audio/wav" });

        const audioURL = URL.createObjectURL(audioBlob);
        setAudioURLs((prevAudioURLs) => [...prevAudioURLs, audioURL]);

        audioChunks1.current = [];
      };

      mediaRecorder1.current.start();

      setThree(false);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause();
      setTwo(true);
      setIsRecording(false);
      setIsRecordingPaused(true);
    }
  };

  const pauseRecording1 = () => {
    if (
      mediaRecorder1.current &&
      mediaRecorder1.current.state === "recording"
    ) {
      mediaRecorder1.current.pause();
    }
  };

  const resumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume();
      setIsRecording(true);
      setIsRecordingPaused(false);
    }
  };

  const resumeRecording1 = () => {
    if (mediaRecorder1.current && mediaRecorder1.current.state === "paused") {
      mediaRecorder1.current.resume();
    }
  };

  const createWavBlob = async (audioBuffer) => {
    const numberOfChannels = audioBuffer.numberOfChannels;
    const sampleRate = audioBuffer.sampleRate;
    const length = audioBuffer.length;

    const interleaved = new Float32Array(length * numberOfChannels);
    for (let channel = 0; channel < numberOfChannels; channel++) {
      const channelData = audioBuffer.getChannelData(channel);
      for (let i = 0; i < length; i++) {
        interleaved[i * numberOfChannels + channel] = channelData[i];
      }
    }

    const wavBuffer = createWavBuffer(
      interleaved,
      numberOfChannels,
      sampleRate
    );

    return new Blob([wavBuffer], { type: "audio/wav" });
  };

  const writeString = (view, offset, string) => {
    for (let i = 0; i < string.length; i++) {
      view.setUint8(offset + i, string.charCodeAt(i));
    }
  };

  const createWavBuffer = (interleaved, numberOfChannels, sampleRate) => {
    const buffer = new ArrayBuffer(44 + interleaved.length * 2);
    const view = new DataView(buffer);

    writeString(view, 0, "RIFF");
    view.setUint32(4, 32 + interleaved.length * 2, true);
    writeString(view, 8, "WAVE");
    writeString(view, 12, "fmt ");
    view.setUint32(16, 16, true);
    view.setUint16(20, 1, true); // PCM format
    view.setUint16(22, numberOfChannels, true);
    view.setUint32(24, sampleRate, true);
    view.setUint32(28, sampleRate * 2 * numberOfChannels, true);
    view.setUint16(32, numberOfChannels * 2, true);
    view.setUint16(34, 16, true); // 16-bit sample size
    writeString(view, 36, "data");
    view.setUint32(40, interleaved.length * 2, true);

    // write the PCM samples
    const offset = 44;
    for (let i = 0; i < interleaved.length; i++) {
      const sample = Math.max(-1, Math.min(1, interleaved[i]));
      view.setInt16(
        offset + i * 2,
        sample < 0 ? sample * 0x8000 : sample * 0x7fff,
        true
      );
    }

    return buffer;
  };

  const processAudioBlob = async (blobdata) => {
    try {
      // Step 1: Decode audio data from Blob
      const arrayBuffer = await blobdata.arrayBuffer();
      const audioContext = new (window.AudioContext ||
        window.webkitAudioContext)();
      const decodedData = await audioContext.decodeAudioData(arrayBuffer);
      const wavBlob = await createWavBlob(decodedData);
      // handleDownloadBlob(wavBlob);
      return wavBlob;
    } catch (error) {
      console.error("Error processing audio blob:", error);
    }
  };

  const stopRecording = () => {
    setSummaries("Saving...");
    // props.setMicroBtnDis(true);
    compRecord(true);
    if (three && audioURLs.length === 0) {
      return;
    }

    if (delClick) {
      audioStream.current = null;
    }
    if (
      mediaRecorder.current &&
      (mediaRecorder.current.state === "recording" ||
        mediaRecorder.current.state === "paused")
    ) {
      mediaRecorder.current.stop();
      audioStream.current !== null &&
        audioStream.current.getTracks().forEach((track) => {
          track.stop();
        });
      audioStream.current = null;
      audioContext.current.close();
    }
    // setTimeout(() => {
    //   setSummaries("Summary Completed");
    // }, 1000);
    setTimeout(() => {
      // setAudioURLs([]);

      setIsRecording(false);
      setIsRecordingPaused(false);
      setOne(false);
      // setSummaries("");
    }, 2000);
  };

  const toDelete = () => {
    if (window.confirm("Are you sure you want to delete?")) {
      setAudioURLs([]);
      setAudioURLs1([]);
      setOne(false);
      setTwo(true);
      setIsRecording(false);
      setIsRecordingPaused(false);
      setDelClick(true);
      //   setSummaries("");
    }
  };

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const handleClose = () => {
    setShowGlobalComponent(false);
    setAudioURLs([]);
    setAudioURLs1([]);
    setOne(false);
    setTwo(true);
    setIsRecording(false);
    setIsRecordingPaused(false);
    setDelClick(true);
  };
  useEffect(() => {
    let interval;
    if (isRecording && !isRecordingPaused) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else if (!isRecordingPaused) {
      clearInterval(interval);
      setTimer(0);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isRecording, isRecordingPaused]);

  useEffect(() => {
    SetUrls(audioURLs);
  }, [audioURLs]);

  // useEffect(() => {
  //   const backdrop = document?.querySelector(".modal-backdrop");
  //   if (
  //     backdrop &&
  //     backdrop.nextElementSibling.classList.contains("audio_small_modal")
  //   ) {
  //     backdrop.style.display = "none";
  //     document.body.style.overflowY = "auto";
  //   }
  // }, [showGlobalComponent]);

  return (
    showGlobalComponent && (
      <Draggable>
        <div className="global_popup_ai">
          {/* <div
            className="modal-content"
            style={{
              // pointerEvents: "auto",
              zIndex: "1050",
              background: "#fff",
              boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
              height: "180px",
            }}
            // onClick={handleModalClick}
          > */}
          <div>
            <div style={{ textAlign: "right" }}>
              <button
                onClick={handleClose}
                style={{ border: "none", background: "transparent" }}
              >
                <Image className="close_icon" src={CloseDoc} />
                {/* ✖  */}
              </button>
            </div>
            <div>
              {/* Your popup content goes here */}
              <div className="text_container">
                {!isRecording && !isRecordingPaused && showGlobalComponent ? (
                  <>
                    <div className="text_container">
                      <div className="record_time_text">
                        {formatTime(timer)}
                      </div>
                      <div className="text_paused">
                        Click to start recording
                      </div>
                      <Image
                        className="record_image1"
                        src={start_record}
                        onClick={() => {
                          startRecording1();
                          startRecording();
                        }}
                      />
                    </div>
                  </>
                ) : isRecording && !isRecordingPaused && showGlobalComponent ? (
                  <>
                    <div className="text_container">
                      <div className="record_time_text">
                        {formatTime(timer)}
                      </div>
                      <div className="text_paused">Recording...</div>
                      <Image
                        className="record_image1"
                        src={on_record}
                        onClick={() => {
                          pauseRecording();
                          pauseRecording1();
                        }}
                      />
                    </div>
                    <div className="record_image_container">
                      <Tooltip>
                        <Image
                          className="m-r-12 record_image"
                          src={pause_delete}
                          onClick={() => {
                            toDelete();
                          }}
                        />
                        <TooltipText className="TooltipText">
                          Delete
                        </TooltipText>
                      </Tooltip>
                      {summaries && !showTick ? (
                        <div className="record_summary_text">{summaries}</div>
                      ) : (
                        <Tooltip>
                          <Image
                            className="record_image"
                            src={accSetup_tick}
                            onClick={() => {
                              stopRecording();
                              // setOpenRecordModal(true);
                              setShowGlobalComponent(true);
                            }}
                          />
                          <TooltipText className="TooltipText">
                            Save
                          </TooltipText>
                        </Tooltip>
                      )}
                    </div>
                  </>
                ) : (
                  !isRecording &&
                  isRecordingPaused &&
                  showGlobalComponent && (
                    <>
                      {!one && (
                        <>
                          <div className="text_container">
                            <div className="record_time_text">
                              {formatTime(timer)}
                            </div>
                            <div className="text_paused">Paused</div>
                            <Image
                              className="record_image1"
                              src={pause_record}
                              onClick={() => {
                                resumeRecording();
                                resumeRecording1();
                              }}
                            />
                          </div>
                          <div className="record_image_container">
                            <Tooltip>
                              <Image
                                className="m-r-12 record_image"
                                src={pause_delete}
                                onClick={() => {
                                  toDelete();
                                }}
                              />
                              <TooltipText className="TooltipText">
                                Delete
                              </TooltipText>
                            </Tooltip>
                            {summaries && !showTick ? (
                              <div className="record_summary_text">
                                {summaries}
                              </div>
                            ) : (
                              <Tooltip>
                                <Image
                                  className=" record_image"
                                  src={accSetup_tick}
                                  onClick={() => {
                                    stopRecording();
                                    setShowGlobalComponent(true);
                                  }}
                                />
                                <TooltipText className="TooltipText">
                                  Save
                                </TooltipText>
                              </Tooltip>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  )
                )}
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </Draggable>
    )
  );
}

export default memo(UploadAudio);
