import { call, put } from "redux-saga/effects";
import moment from "moment";
import {
  MsgResponse,
  setMessageList,
  setNewMsgCount,
  setPreviewDownload,
  setSecureMessageList,
  setSecureSearch,
  setTotalUnreadCount,
} from "../../StateManagement/Reducers/MessageState";
import {
  MessageListApi,
  MessageSendApi,
  PreviewDownloadApiMessage,
  SecureMessageListApi,
  SecureMessageSearchApi,
  TotalUnreadCountApi,
  UnreadCountUpdateApi,
} from "../Apis/MessageApi";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";
import { ProviderListApi } from "../Apis/PatientApi";
import { SetGetMsgList } from "../../StateManagement/Reducers/PatientState";

export function* SendMsgWorker({ payload }) {
  const payload2 = payload.get("message");
  const decryptpayload = Decrypt_Value(payload2, "vozo");
  const userId = decryptpayload.sender_id;
  const receiveId = decryptpayload.receive_id2;
  try {
    const res = yield call(MessageSendApi, payload);
    if (res.status === 200) {
      const getData = { receiveId: receiveId, userId: userId };
      let ecrypted = Encrypt_Value(getData, "vozo");
      const resL = yield call(MessageListApi, ecrypted);
      if (resL.status === 200) {
        let Decrypted_data = Decrypt_Value(resL.data, "vozo");
        yield put(setMessageList(Decrypted_data));
      }
      const res3 = yield call(ProviderListApi);
      if (res3.status === 200) {
        const Data = Decrypt_Value(res3.data, "vozo");

        yield put(SetGetMsgList(Data));
      }
    }
  } catch (e) {
    yield put(MsgResponse("CAN'T POST"));
  }
}

export function* MessageList({ payload }) {
  const credential = payload;
  const payload2 = payload["userId"];
  const credential2 = { uid: payload2 };

  let ecrypted = Encrypt_Value(credential, "vozo");
  let ecrypted2 = Encrypt_Value(credential2, "vozo");
  try {
    const res = yield call(MessageListApi, ecrypted);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "vozo");

      yield put(setMessageList(Decrypted));

      try {
        const res2 = yield call(TotalUnreadCountApi, ecrypted2);
        if (res2.status === 200) {
          const Data = Decrypt_Value(res2.data, "vozo");
          yield put(setTotalUnreadCount(Data));
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  } catch (e) {
    yield put(setMessageList([]));
  }
}

export function* PreviewDownloadApi({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "vozoportal");
  try {
    const res = yield call(PreviewDownloadApiMessage, credential);
    if (res.status === 200) {
      const decrptedResponse = Decrypt_Value(res.data, "vozoportal");
      yield put(setPreviewDownload(decrptedResponse));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* UnreadCountUpdate({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "vozo");
  try {
    const res = yield call(UnreadCountUpdateApi, credential);
    if (res.status === 200) {
      try {
        const res3 = yield call(ProviderListApi);
        if (res3.status === 200) {
          const Data = Decrypt_Value(res3.data, "vozo");
          yield put(SetGetMsgList(Data));
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* TotalUnreadCount({ payload }) {
  const data = payload;
  let credential = Encrypt_Value(data, "vozo");
  try {
    const res = yield call(TotalUnreadCountApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");

      yield put(setTotalUnreadCount(Data));
    }
  } catch (e) {
    console.log(e.message);
  }
}

export function* SecureMessageList({ payload }) {
  const payload2 = payload["userId"];

  try {
    const res = yield call(SecureMessageListApi, payload2);
    if (res.status === 200) {
      let Decrypted = Decrypt_Value(res.data, "vozo");

      yield put(setSecureMessageList(Decrypted));
      if (Decrypted.length > 0) {
        var active = 0;
        active = Decrypted.filter(
          (item) =>
            moment(item.date).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD") &&
            item.Sender !== "Provider" &&
            parseInt(item.unread) !== 0
        );

        yield put(setNewMsgCount(active.length));
      }
    }
  } catch (e) {
    yield put(setMessageList([]));
  }
}

export function* SecureMessagePatientSearch({ payload }) {
  const data = payload.search;
  let credential = Encrypt_Value(data, "vozo");
  yield put(setSecureSearch(""));
  try {
    const res = yield call(SecureMessageSearchApi, credential);
    if (res.status === 200) {
      const Data = Decrypt_Value(res.data, "vozo");
      yield put(setSecureSearch(Data));
    }
  } catch (e) {
    yield put(setSecureSearch([]));
    console.log(e.message);
  }
}
