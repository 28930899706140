import React, { createContext, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { BsCheckCircle } from "react-icons/all";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [showGlobalComponent, setShowGlobalComponent] = useState(false);
  const [globalAlert, setGlobalAlert] = useState(false);
  const [globalMsg, setGlobalMsg] = useState(false);
  const [encounterID, setEncounterID] = useState(0);
  const [provID, setUserID] = useState(0);
  const [patID, setPatientID] = useState(0);
  const [eid, setEid] = useState(0);
  return (
    <GlobalContext.Provider
      value={{
        showGlobalComponent,
        setShowGlobalComponent,
        setGlobalAlert,
        setGlobalMsg,
        encounterID,
        provID,
        patID,
        setEncounterID,
        setUserID,
        setPatientID,
        setEid,
        eid,
      }}
    >
      {children}
      {globalAlert && (
        <Modal className="alert-popup-message" show={true}>
          <div className="alert msg alert-success" role="alert">
            <BsCheckCircle className="icons-bs" /> {globalMsg}
          </div>
        </Modal>
      )}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
