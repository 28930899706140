import styled from "styled-components";
import { Modal } from "../../../StyledComponents";
import { ModalFooter } from "../../../components/setting/PatientPortalSettings/StyledComponents";
import { ErrorMessage } from "../../../components/setting/Inventory/styles";

export const ModalsendFax = styled(Modal)`
  width: 100%;
  height: 100%;
  display: flex;
  z-index: ${(p) => (p.zIndex ? p.zIndex : "1050")};
`;

export const TextArea = styled.textarea`
  outline: none;
  width: 100%;
  min-height: ${(p) => (p.minHeight ? p.minHeight : "174px")};
  padding-left: 10px;
  padding-right: 20px;
  padding-top: 10px;
  border: 1px solid rgba(46, 46, 46, 0.25);
  border-radius: 4px;
  &:hover {
    border: 1px solid rgba(46, 46, 46, 0.25);
  }
  color: ${(p) => p.color && p.color};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily}, sans-serif;
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  width: ${(p) => p.width && p.width};
  margin: ${(p) => p.margin && p.margin};
  resize: ${(p) => p.resize && p.resize};
`;

export const ModalFooter1 = styled(ModalFooter)`
  height: ${(p) => (p.height ? p.height : "unset")};
  padding: ${(p) => p.padding && p.padding};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
`;

export const ErrorFax = styled(ErrorMessage)`
  margin: ${(p) => p.margin && p.margin};
`;
