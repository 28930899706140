import React, { useState } from "react";
import vozoExploreIcon from "../../assets/images/vozo-explore-icon.png";
import { Div } from "./styles";
import { Modal } from "react-bootstrap";

import UserGuide from "../popups/UserGuide";
var CryptoJS = require("crypto-js");

const Encode_Decode_JSON = {
  stringify: function (DecodingVal) {
    var Data_Val = {
      Encode: DecodingVal.ciphertext.toString(CryptoJS.enc.Base64),
    };
    if (DecodingVal.iv) Data_Val.Decentral = DecodingVal.iv.toString();
    if (DecodingVal.salt) Data_Val.EndCode = DecodingVal.salt.toString();
    return JSON.stringify(Data_Val);
  },
  parse: function (StrVal) {
    var Data_Val = JSON.parse(StrVal);
    var DecodingVal = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(Data_Val.Encode),
    });
    if (Data_Val.Decentral)
      DecodingVal.Decentral = CryptoJS.enc.Hex.parse(Data_Val.Decentral);
    if (Data_Val.EndCode)
      DecodingVal.salt = CryptoJS.enc.Hex.parse(Data_Val.EndCode);

    return DecodingVal;
  },
};

const Decrypt_Value = (data, key) => {
  return JSON.parse(
    CryptoJS.AES.decrypt(data, key, {
      format: Encode_Decode_JSON,
    }).toString(CryptoJS.enc.Utf8)
  );
};
const ExploreWidget = ({ explore }) => {
  const [showuserguide, setshowuserguide] = useState(false);
  const provname = localStorage.getItem("provider_name")
    ? Decrypt_Value(localStorage.getItem("provider_name"), "vozo")
    : "";
  const handleCloseApp = () => setshowuserguide(false);

  return (
    <>
      <Div className={explore}>
        <img src={vozoExploreIcon} className="explore-vozo-img" alt="logo" />
        <h1 className="explore-vozo-heading">Explore Vozo</h1>
        <p className="explore-vozo-para">Follow these steps to get running</p>
        <button
          className="explore-vozo-button"
          onClick={() => setshowuserguide(true)}
        >
          Get Started
        </button>
      </Div>
      <Modal
        className="user-guide-popup-box zIndex1100"
        show={showuserguide}
        onHide={() => setshowuserguide(false)}
      >
        <UserGuide close={handleCloseApp} ProviderName={provname} />
      </Modal>
    </>
  );
};

export default ExploreWidget;
