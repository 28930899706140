import { call, put } from "redux-saga/effects";
import {
  SetAuditLogData,
  SetPatientSearchData,
  SetUserSearchData,
  SetAuditLogTenData,
  SetPatientSearchNoResult,
  SetUserSearchNoResult,
  setBillingFacility,
  SetPracticeDashboard,
  SetPracticeTenDashboard,
  SetDeleteInvoice,
  SetTotalCreditAmt,
  SetAddPayment,
  SetPatCreditPay,
  SetCompOpen,
} from "../../StateManagement/Reducers/ScheduleState";
import {
  AuditLogApi,
  PatientSearchDataApi,
  UserSearchDataApi,
  BillingFacilityApi,
  PracticeDashboardApi,
  DeleteInvoiceApi,
  TotalCreditAmtApi,
  TotalInvoiceIdApi,
  PatCreditPayApi,
} from "../Apis/ScheduleApi";
import { Decrypt_Value, Encrypt_Value } from "../EncryptDecrypt";

export function* AuditLog({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(AuditLogApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");

      if (payload.offset === 0) {
        yield put(SetAuditLogData(decryptResponse));
      } else {
        yield put(SetAuditLogTenData(decryptResponse));
      }
    }
  } catch (e) {
    if (payload.offset === 0) {
      yield put(SetAuditLogData([]));
    } else {
      console.log(e.message);
    }
  }
}

export function* PatientSearchData({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(PatientSearchDataApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      if (decryptResponse.length < 1) {
        yield put(SetPatientSearchNoResult(""));
      } else {
        yield put(SetPatientSearchNoResult("1"));
      }
      yield put(SetPatientSearchData(decryptResponse));
    }
  } catch (e) {
    yield put(SetPatientSearchData([]));
    console.log(e.message);
  }
}

export function* UserSearchData({ payload }) {
  const Data = Encrypt_Value(payload, "vozo");
  try {
    const res = yield call(UserSearchDataApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      if (decryptResponse.length < 1) {
        yield put(SetUserSearchNoResult(""));
      } else {
        yield put(SetUserSearchNoResult("1"));
      }
      yield put(SetUserSearchData(decryptResponse));
    }
  } catch (e) {
    yield put(SetUserSearchData([]));
    console.log(e.message);
  }
}

export function* BillingFacilityWorker() {
  try {
    const res = yield call(BillingFacilityApi);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      if (decryptResponse === false) {
        yield put(setBillingFacility(null));
      } else {
        yield put(setBillingFacility(decryptResponse.id));
      }
    }
  } catch (e) {
    yield put(setBillingFacility(null));
    console.log(e.message);
  }
}

export function* PracticeDashboard({ payload }) {
  try {
    const Data = Encrypt_Value(payload, "vozo");
    const res = yield call(PracticeDashboardApi, Data);
    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      decryptResponse.forEach((practice) => {
        const codes = [];
        if (practice.services !== null) {
          practice.services.forEach((service) => {
            codes.push(service.code);
            practice.serviceCodes = codes;
          });
        }
      });
      if (payload.offset === 0) {
        yield put(SetPracticeDashboard(decryptResponse));
      } else {
        yield put(SetPracticeTenDashboard(decryptResponse));
      }
    }
  } catch (e) {
    yield put(SetPracticeDashboard([]));
  }
}
export function* deleteInvoiceData({ payload }) {
  try {
    const Data = Encrypt_Value(payload, "vozo");
    const res = yield call(DeleteInvoiceApi, Data);
    if (res.status === 200) {
      yield put(SetDeleteInvoice(res.data));
    }
  } catch (e) {
    yield put(SetDeleteInvoice(""));
  }
}

export function* TotalCreditAmtData({ payload }) {
  try {
    const res = yield call(TotalCreditAmtApi, payload.eid);
    if (res.status === 200) {
      yield put(SetCompOpen(false));
      yield put(SetPatCreditPay(""));
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetTotalCreditAmt(decryptResponse));
    }
  } catch (e) {
    yield put(SetTotalCreditAmt(""));
  }
}

export function* AddPaymentAmt({ payload }) {
  try {
    const Data = Encrypt_Value({ inid: payload.invoice_id }, "vozo");
    const res = yield call(TotalInvoiceIdApi, payload.eid, Data);

    if (res.status === 200) {
      const decryptResponse = Decrypt_Value(res.data, "vozo");
      yield put(SetAddPayment(decryptResponse));
      if (payload.amount === false) {
        yield put(SetCompOpen(true));
      } else if (payload.amount < decryptResponse[1]) {
        let data1 = {
          invoice_id: payload.invoice_id,
          amount: payload.amount,
          userid: payload.userid,
        };
        const Data1 = Encrypt_Value(data1, "vozo");
        const res2 = yield call(PatCreditPayApi, payload.eid, Data1);
        if (res2.status === 200) {
          const res3 = yield call(TotalInvoiceIdApi, payload.eid, Data);
          if (res3.status === 200) {
            const decryptResponse1 = Decrypt_Value(res3.data, "vozo");
            yield put(SetAddPayment(decryptResponse1));
            yield put(SetCompOpen(true));
          }
        }
      } else if (payload.amount >= decryptResponse[1]) {
        let data2 = {
          invoice_id: payload.invoice_id,
          amount: decryptResponse[1],
          userid: payload.userid,
        };
        const Data2 = Encrypt_Value(data2, "vozo");
        const res4 = yield call(PatCreditPayApi, payload.eid, Data2);
        if (res4.status === 200) {
          const decryptResponse2 = Decrypt_Value(res4.data, "vozo");
          yield put(SetPatCreditPay(1));
        }
      }
    }
  } catch (e) {
    console.log(e.message);
    yield put(SetAddPayment(""));
  }
}
