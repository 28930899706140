import styled from "styled-components";
import { Div, Button, Atag } from "../../billing//CardTransactions/styles";
import { Container, ModalHead } from "../AddClinicians/style";
import { H3 } from "./StyledComponents";
import { Modal } from "../AddClinicians/style";

export const Image = styled.img`
  width: 100%;
  height: max-content;
`;

export const Div1 = styled(Div)`
  text-align: ${(p) => (p.align ? p.align : "left")};
  padding: ${(p) => p.padding && p.padding};
  display: ${(p) => p.display && p.display};
  background: ${(p) => p.background && p.background};
  border-top: ${(p) => p.borderTop && p.borderTop};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  float: ${(p) => p.float && p.float};
  cursor: ${(p) => p.cursor && p.cursor};
  align-items: ${(p) => p.alignItems && p.alignItems};
  position: ${(p) => p.position && p.position};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  width: ${(p) => p.width && p.width};
`;

export const EblPatPort = styled(Div1)`
  position: relative;
  width: ${(p) => (p.patPort === 1 ? "" : "945px")};
  position: relative;
  margin: 24px 32px 0px 20px;
  display: flex;
  align-items: flex-start;
`;

export const OnlApptReq = styled(Div1)`
  position: relative;
  float: left;
  margin: ${(p) =>
    p.apptChek === 1 ? "24px 32px 0px 20px" : "24px 32px 9px 20px"};
  display: flex;
  align-items: flex-start;
`;

export const PortDiv = styled(Div1)`
  min-height: 51px;
  background-color: #eaf2fc;
  border: 1px solid #2c7be5;
  border-radius: 4px;
  margin: 21px 45px 0 20px;
`;

export const SetupReq = styled(Div1)`
  width: 100%;
  height: 53px;
  border-radius: 4px;
  background-color: rgba(87, 191, 127, 0.1);
  border: 0.5px solid #57bf7f;
  display: flex;
  padding: 0 0 0 24px;
`;

export const DropDownDiv = styled(Div1)`
  border: 1px solid #cbd5e0;
  font-size: 14px;
  color: #718096;
  padding: 0 0 0 12px;
  border-radius: 4px;
`;

export const Button1 = styled(Button)`
  font-family: ${(p) => (p.family ? p.family : "Inter")};
  font-weight: ${(p) => p.weight && p.weight};
  border-radius: ${(p) => p.radius && p.radius};
  margin: ${(p) => p.margin && p.margin};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.Lheight && p.Lheight};
  padding: ${(p) => p.padding && p.padding};
`;

export const ActvBtn = styled(Button1)`
  width: 58px;
  height: 21px;
  background-color: #57bf7f;
  border-radius: 10.5px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
`;

export const ApptReqH3 = styled(H3)`
  font-size: 16px;
  font-weight: 500;
  color: #2d3748;
  font-family: "Work Sans";
`;

export const P = styled.p`
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => p.weight && p.weight};
  font-family: ${(p) => (p.family ? p.family : "Inter")};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lheight && p.lheight};
  width: ${(p) => p.width && p.width};
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  text-align: ${(p) => (p.align ? p.align : "left")};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
`;

export const CheckBoxLabel = styled.label`
  top: 0;
  left: 0;
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  margin-top: 0px;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: ${(p) => p.afrWidth && p.afrWidth};
    height: ${(p) => p.afrHeight && p.afrHeight};
    margin: ${(p) => p.afrMrgn && p.afrMrgn};
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBoxEbl = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  cursor: pointer;
  width: 42px;
  height: 23px;
  position: absolute;
  margin-right: 6px;
  &:checked + ${CheckBoxLabel} {
    background: #2c7be5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      margin: 0 0 0 21px;
      transition: 0.2s;
      margin-top: 3px;
    }
  }
`;

export const CheckBoxReq = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  cursor: pointer;
  width: 26px;
  height: 16px;
  position: absolute;
  margin-right: 6px;
  &:checked + ${CheckBoxLabel} {
    background: #2c7be5;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 11px;
      height: 11px;
      margin: 0 0 0 12px;
      transition: 0.2s;
      margin-top: 3px;
    }
  }
`;

export const H4 = styled.h4`
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => p.weight && p.weight};
  font-family: ${(p) => (p.family ? p.family : "Inter")};
  font-size: ${(p) => p.size && p.size};
  text-align: ${(p) => (p.align ? p.align : "left")};
  padding: ${(p) => p.padding && p.padding};
`;

export const H2 = styled.h2`
  font-size: ${(p) => p.size && p.size};
  font-family: ${(p) => p.family && p.family};
  font-weight: ${(p) => p.weight && p.weight};
  margin: ${(p) => p.margin && p.margin};
`;

export const Input = styled.input`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  border: ${(p) => p.border && p.border};
  margin: ${(p) => p.margin && p.margin};
  outline: none;
  font-weight: ${(p) => p.weight && p.weight};
  font-size: ${(p) => p.size && p.size};
  font-family: ${(p) => p.family && p.family};
  padding: ${(p) => p.padding && p.padding};
`;

export const ContModal = styled(Container)`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  box-shadow: ${(p) => p.shadow && p.shadow};
`;

export const HeadModal = styled(ModalHead)`
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  padding: ${(p) => p.padding && p.padding};
`;
export const TagA = styled(Atag)`
  text-decoration: ${(p) => p.textDecoration && p.textDecoration};
  font-size: ${(p) => p.size && p.size};
  margin: ${(p) => p.margin && p.margin};
  &:hover {
    text-decoration: ${(p) => p.textDecoration && p.textDecoration};
  }
`;

export const CheckBoxLabelN = styled.label`
  position: ${(p) => p.position && p.position};
  top: 1px;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background: #ffff;
  border: 0.5px solid #d8e0f0;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin: 3px;
    background: #ffffff;
  }
`;

export const TogggleCheck = styled.input`
  position: ${(p) => p.position && p.position};
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  height: 16px;
  width: 16px;
  position: ${(p) => (p.position ? p.position : "relative")};
  left: ${(p) => (p.left ? p.left : "4px")};
  background: #ffff;
  &:checked + ${CheckBoxLabelN} {
    background: #407bff;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
    }
  }
`;

export const ModalShow = styled(Modal)`
  color: #155724;
  background-color: rgba(0, 0, 0, 0.25);
  border-color: #c3e6cb;
`;
export const ModalCont = styled(Div1)`
  background-color: #d4edda;
  width: 300px;
  height: auto;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  svg {
    font-size: 30px;
    padding-right: 5px;
  }
`;

export const DropDown = styled.h4`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 0px;
  svg {
    font-size: 14px;
    float: right;
    margin-right: 5px;
    margin-top: 7px;
  }
`;

export const DropDownListContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 9px 42px rgba(0, 0, 0, 0.1), 0px 0px 6px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  min-width: ${(p) => p.minWidth && p.minWidth};
  ${({ overflowY }) =>
    overflowY &&
    `
  overflow-y : auto;
  max-height : 45%;
  `}
`;

export const DropDownList = styled.ul`
  padding: 0;
  margin: 0;
  option {
    padding: 6px 8px;
    font-family: "Manrope";
    font-size: 14px;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  font-size: 14px;
  line-height: 16px;
  color: #414141;
  padding: 10px 16px;
  cursor: poniter;
  &:hover {
    background: #767676;
    cursor: pointer;
    color: #fff;
  }

  width: ${(p) => p.width && p.width};
`;

export const ListMacros = styled(ListItem)`
  padding: 0;
`;

export const Option = styled.option`
  height: ${(p) => p.height && p.height};
  background-color: ${(p) => p.bgColor && p.bgColor};
  color: ${(p) => p.color && p.color};
`;
