import styled, { keyframes } from "styled-components";

export const Label = styled.label`
  cursor: pointer;
  display: ${(p) => p.display && p.display};
  padding: ${(p) => p.padding && p.padding};
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "6px")};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "3px")};
  text-align: ${(p) => p.textAlign && p.textAlign};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "700")};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "14px")};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
`;

export const Input = styled.input`
  &.radio-input {
    margin: 0;
    visibility: hidden;
  }
  &.radio-input:checked + span {
    border: 2px solid #007bff;
  }

  &.radio-input:checked + span:after {
    opacity: 1;
  }
`;

export const PageTitle = styled.h1`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight || "700"};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  display: flex;
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  // color: #232934;
  // padding: 20px;
  color: ${(p) => p.color || "#232934"};
  padding: ${(p) => (p.padding ? p.padding : "20px")};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  position: ${(p) => p.position && p.position};
  margin: ${(p) => p.margin && p.margin};
  margin-right: ${(p) => p.marginRight && p.marginRight};
`;

export const Div = styled.div`
  display: ${(p) => p.display && p.display};
  height: ${(p) => (p.height ? p.height : "auto")};
  width: ${(p) => {
    if (p.widthOffset) {
      return `calc(100% - ${p.widthOffset})`;
    } else {
      return p.width || "auto";
    }
  }};
  margin: ${(p) => p.margin && p.margin};
  ${(p) => p.padding && `padding: ${p.padding};`}
  ${(p) => p.border && `border: ${p.border};`}
  ${(p) => p.boxShadow && `box-shadow: ${p.boxShadow};`}  
  ${(p) => p.fontSize && `font-size : ${p.fontSize};`}
  ${(p) => p.overflow && `overflow : ${p.overflow};`}
  ${(p) => p.alignItems && `align-items : ${p.alignItems};`}
  ${(p) => p.justifyContent && `justify-content : ${p.justifyContent};`}
  ${(p) => p.textShadow && `text-shadow : ${p.textShadow};`}
  ${(p) => p.borderRadius && `border-radius: ${p.borderRadius};`}
  ${(p) => p.borderTlRadius && `border-top-left-radius: ${p.borderTlRadius};`}
  ${(p) => p.borderTrRadius && `border-top-right-radius: ${p.borderTrRadius};`}
  ${(p) =>
    p.borderBlRadius && `border-bottom-left-radius: ${p.borderBlRadius};`}
  ${(p) =>
    p.borderBrRadius && `border-bottom-right-radius: ${p.borderBrRadius};`}
  z-index: ${(p) => p.zIndex && p.zIndex};
  background-color: ${(p) => p.backColor || "white"};
  color: ${(p) => p.color || "black"};
  border: ${(p) => p.border && p.border};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  border-top: ${(p) => p.borderTop && p.borderTop};

  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding: ${(p) => p.padding && p.padding};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  border: ${(p) => p.border && p.border};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  background: ${(p) => p.background && p.background};
  left: ${(p) => p.left && p.left};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  ${(p) => p.borderBox && "box-sizing : border-box;"}
  ${(p) => {
    if (p.flexCenterAll) {
      return `display : flex;
      align-items : center;
      justify-content : center;`;
    } else if (p.flexCenterVertical) {
      return `display : flex;
      align-items : center;
      `;
    } else if (p.flexCenterHorizontal) {
      return `display : flex;
    justify-content : center;
    `;
    } else if (p.flexCenterBetween) {
      return `display : flex;
    align-items : start;
    justify-content : space-between;
    `;
    }
  }}
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  border-right: ${(p) => p.borderRight && p.borderRight};
  align-items: ${(p) => p.alignItems && p.alignItems};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  outline-style: ${(p) => p.outlineStyle && p.outlineStyle};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  cursor: ${(p) => p.cursor && p.cursor};

  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  ${({ hoverBlue, disabled }) => {
    if (hoverBlue && !disabled) {
      return `
      &:hover{
        color : white;
        background : #2C7BE5;
        transition-duration : 0.4s;
      }
      `;
    } else if (disabled) {
      return `
      pointer-events : none;
      `;
    }
  }}

  &:hover {
    background-color: ${(p) => p.backcolor && p.backcolor};
  }

  min-width: ${(p) => p.minWidth && p.minWidth};
  min-height: ${(p) => p.minHeight && p.minHeight};
  grid-column: ${(p) => p.gridColumn && p.gridColumn};
  transform: ${(p) => p.transform && p.transform};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  overflow: ${(p) => p.overflow && p.overflow};
`;

// Table Styled Component //

export const Table = styled.table`
  margin-top: ${(p) => (p.marginTop ? p.marginTop : "1px")};
  /* width: 100%; */
  border-spacing: 0px;
  min-width: ${(p) => p.minWidth && p.minWidth};
  padding: ${(p) => p.padding && p.padding};
  overflow: ${(p) => p.overflow && p.overflow};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  height: ${(p) => (p.height ? p.height : "100px")};
  border: ${(p) => (p.border ? p.border : "1px solid #E2E8ED")};
  border-top: ${(p) => (p.borderTop ? p.borderTop : "none")};
  width: ${(p) => (p.width ? p.width : "100%")};
  margin: ${(p) => p.margin && p.margin};
`;

export const TableHead = styled.thead`
  text-align: left;
  background-color: ${(props) => props.background};
  display: ${(p) => (p.scroll ? "table" : "")};
  width: ${(p) => (p.scroll ? "100%" : "")};
  table-layout: ${(p) => (p.scroll ? "fixed" : p.tableLayout)};
  height: ${(p) => p.height && p.height};

  display: ${(p) => p.display && p.display};
  width: ${(p) => p.width && p.width};
`;

export const TableBody = styled.tbody`
  text-align: left;
  table-layout: ${(p) => p.tableLayout && p.tableLayout};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  display: ${(p) => p.display && p.display};
  background-color: ${(props) => props.background};
  overflow-y: ${(p) => (p.scroll ? "scroll" : "")};
  display: ${(p) => (p.scroll ? "block" : "")};
  height: ${(p) => (p.scroll ? p.height : "")};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  background-color: ${(props) => props.background};
`;

export const TableHeadCell = styled.th`
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "500")};
  font-size: ${(p) => (p.fontSize ? p.fontSize : "14px")};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "16px")};
  color: ${(p) => (p.color ? p.color : "#777984")};
  /* padding: ${(p) => (p.padding ? p.padding : "20px 20px 20px 30px")}; */
  /* padding-left: 24px; */
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  min-width: ${(p) => p.minWidth && p.minWidth};
  padding-left: ${(p) => (p.paddingLeft ? p.paddingLeft : "16px")};
  padding-top: ${(p) => (p.paddingTop ? p.paddingTop : "20px")};
  padding-bottom: ${(p) => (p.paddingBottom ? p.paddingBottom : "20px")};
`;

export const TableBodyCell = styled.td`
  font-size: ${(p) => (p.fontSize ? p.fontSize : "13px")};
  align-items: ${(p) => p.alignItems && p.alignItems};
  cursor: ${(p) => p.cursor && p.cursor};
  display: ${(p) => p.display && p.display};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "400")};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "16px")} !important;
  color: ${(p) => (p.color ? p.color : " #000000")};
  /* padding: ${(p) => (p.padding ? p.padding : "16px 20px 16px 30px")}; */
  text-align: ${(p) => p.textAlign && p.textAlign};
  border-bottom: ${(p) => (p.bodered ? "1px solid #D8E0F0" : "none")};
  padding-left: ${(p) => (p.paddingLeft ? p.paddingLeft : "16px")};
   padding-top: ${(p) => (p.paddingTop ? p.paddingTop : "12px")};
  padding-bottom: ${(p) => (p.paddingBottom ? p.paddingBottom : "12px")};
  width: ${(p) => p.width && p.width};
  text-transform: capitalize;
  word-break: ${(p) => p.wordBreak && p.wordBreak};
  border: ${(p) => p.border && p.border};
    padding-right: ${(p) => (p.paddingRight && p.paddingRight )};

`;
export const TableRow = styled.tr`
  text-align: left;
  border-bottom: ${(p) => (p.borderbottom ? "1px solid #D8E0F0" : "none")};
  background-color: ${(props) => props.background};
  &:last-child ${TableBodyCell} {
    border-bottom: ${(p) => (p.bbtom ? "1px solid #D8E0F0" : "none")};
  }
  display: ${(p) => (p.scroll ? "table" : p.display)};
  width: ${(p) => (p.scroll ? "100%" : p.width)};
  table-layout: ${(p) => (p.scroll ? "fixed" : p.tableLayout)};
  justify-content: ${(p) => p.justifyCont && p.justifyCont};
  &.hover_row:hover {
    overflow-x: hidden;
    background-color: #fafafa;
    //margin: 5px;
    // margin-right:4px;
  }
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  cursor: ${(p) => p.cursor && p.cursor};
  border-bottom: ${(p) => (p.borderbottom ? "1px solid #D8E0F0" : "none")};
  font-weight: ${({ fontWeight }) => fontWeight && fontWeight};
  font-family: ${({ fontFamily }) => fontFamily && fontFamily};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  font-size: ${({ fontSize }) => fontSize && fontSize};
  padding: ${({ padding }) => padding && padding};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
`;
// Table Styled Component //

const ellipsisAnimation = keyframes`
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
`;

export const Button = styled.button`
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  height: ${(p) => p.height && p.height};
  border-radius: ${(p) => (p.borderRadius ? p.borderRadius : "8px")};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  background: ${(p) => p.background && p.background};
  color: ${(p) => (p.color ? p.color : "#ffffff")};
  border: ${(p) => (p.border ? p.border : "none")};
  padding: ${(p) => p.padding && p.padding};
  margin: ${(p) => p.margin && p.margin};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  left: ${(p) => p.left && p.left};
  right: ${(p) => p.right && p.right};
  top: ${(p) => p.top && p.top};
  text-align: ${(p) => p.textAlign && p.textAlign};
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
  svg {
    font-size: ${(p) => p.svgfontSize && p.svgfontSize};
    margin-right: ${(p) => p.svgmarginRight && p.svgmarginRight};
  }
  ${(p) =>
    p.disabled &&
    `
      cursor: not-allowed;
      `}

  &.save_active:active {
    background-color: rgba(44, 123, 229, 0.75);
  }
  &.save_active:disabled {
    background-color: rgba(44, 123, 229, 0.5);
    cursor: not-allowed;
  }
  &.close_active:active {
    background-color: rgba(44, 123, 229, 0.1);
  }
  &.close_active:disabled {
    color: rgba(44, 123, 229, 0.5);
    background-color: #fff;
    cursor: not-allowed;
  }
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
  &:active {
    background-color: ${(p) =>
      p.activeBackgroundColor || p.hoverBackColor || "initial"};
  }

  &.loading::after {
    content: "";
    display: inline-block;
    width: 1em;
    animation: ${ellipsisAnimation} 1s steps(4, end) infinite;
  }
`;
export const SpinnerDiv = styled.div`
  height: ${({ height }) => (height ? height : "380px")};
  width: ${(p) => p.width && p.width};
  display: grid;
  place-items: center;
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: ${(p) => (p.width ? p.width : "100vw")};
  height: ${(p) => (p.height ? p.height : "100vh")};
  display: ${(p) => (p.show ? "flex" : "none")};
  background-color: ${(p) =>
    p.backcolor ? p.backcolor : "rgba(102, 112, 133, 0.4)"};
  justify-content: center;
  align-items: center;
  animation: fade 0.7s linear forwards;
  @keyframes fade {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ModalDiv = styled.div`
  cursor: pointer;
  width: 395px;
  height: 59px;
  position: ${(p) => (p.position ? p.position : "absolute")};
  top: ${(p) => (p.top ? p.top : "32.5%")};
`;
export const BillingBlock = styled.span`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: ${({ disabled, color }) =>
    disabled ? "light gray" : color ? color : "blue"};
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : "1%")};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
`;

export const Form = styled.form`
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
`;

export const FormLabel = styled.label`
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  color: ${(p) => p.color && p.color};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
`;

export const LinkA = styled.a`
  display: none;
  @media print {
    display: block;
  }
`;
export const Link = styled.a`
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-decoration: ${(p) => p.textDecoration && p.textDecoration};
  font-size: ${(p) => p.fontSize && p.fontSize};
  ${(p) =>
    p.hoverBlue &&
    `&:hover {
    color: blue !important;
    text-decoration: underline !important;
  }`}
`;
export const FormGroup = styled.div`
  margin: ${(p) => p.margin && p.margin};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  display: ${(p) => p.display && p.display};
  position: ${(p) => p.position && p.position};
  align-items: ${(p) => p.alignItems && p.alignItems};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  width: ${(p) => p.width && p.width};
  flex: ${(p) => p.flex && p.flex};
  left: ${(p) => p.left && p.left};
`;

export const SelectInput = styled.div`
  width: ${(p) => p.width && p.width};
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  height: ${(p) => p.height && p.height};
  border: ${(p) => p.border && p.border};
  background: ${(p) => p.background && p.background};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  padding-left: ${(p) => p.pl && p.pl};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  display: ${(p) => p.display && p.display};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  text-align: ${(p) => (p.textAlign ? p.textAlign : "center")};
  position: relative;
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overFlow && p.overFlow};
`;

export const DropdownImage = styled.img`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  position: absolute;
  cursor: ${(p) => (p.cursor ? p.cursor : "pointer")};
  margin-top: ${(p) => (p.marginTop ? p.marginTop : "-24px")};
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : "385px")};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
`;

export const Image = styled.img`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  cursor: ${(p) => p.cursor && p.cursor};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  top: ${(p) => p.top && p.top};
  right: ${(p) => p.right && p.right};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  display: ${(p) => p.display && p.display};
  position: ${(p) => p.position && p.position};
`;

export const DropDownDiv = styled.div`
  margin: ${(p) => p.margin && p.margin};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  background: ${(p) => p.background && p.background};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-size: ${(p) => p.fontSize && p.fontSize};
  display: grid;
  padding: ${(p) => p.padding && p.padding};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  border: ${(p) => p.border && p.border};
  position: absolute;
  z-index: 1000;
  min-height: ${(p) => p.minHeight && p.minHeight};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  overflow-y: ${(p) => p.overflowY && p.overflowY};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  left: ${(p) => p.left && p.left};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  overflow: ${(p) => p.overflow && p.overflow};
`;

export const DropdownOptions = styled.label`
  display: ${(p) => p.display && p.display};
  color: ${(p) => p.color && p.color};
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : "400")};
  line-height: ${(p) => (p.lineHeight ? p.lineHeight : "19px")};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  padding: ${(p) => p.padding && p.padding};
  text-align: ${(p) => p.textAlign && p.textAlign};
  font-size: ${(p) => p.fontSize && p.fontSize};
  &:hover {
    background-color: #2c7be5 !important;
    color: #ffffff !important;
  }
  cursor: pointer;
`;

export const Span = styled.p`
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  color: ${(p) => p.color && p.color};
  font-size: ${(p) => p.fontSize && p.fontSize};
  font-family: ${(p) => p.fontFamily && p.fontFamily}, sans-serif;
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  align-items: ${(p) => p.alignItems && p.alignItems};
  text-shadow: ${(p) => p.textShadow && p.textShadow};
  position: ${(p) => p.position && p.position};
  left: ${(p) => p.left && p.left};
  margin: ${(p) => p.margin && p.margin};
  top: ${(p) => p.top && p.top};
  cursor: ${(p) => p.cursor && p.cursor};
  &.Customizerole-edit-btn {
    @media (max-width: 788px) {
      font-size: 11px;
      font-weight: 400;
    }
  }

  &.roles-edit-btn {
    @media (max-width: 788px) {
      font-size: 11px;
      font-weight: 400;
      postition: absolute;
    }
  }
`;

export const Text = styled.p`
  background: ${(p) => p.background && p.background};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  color: ${(p) => p.color && p.color};
  font-family: ${(p) => p.fontFamily && p.fontFamily}, sans-serif;
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  text-align: ${(p) => p.textAlign && p.textAlign};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  font-size: ${(p) => p.fontSize && p.fontSize};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  cursor: ${(p) => p.cursor && p.cursor};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  text-align: ${(p) => p.textAlign && p.textAlign};
  font-style: ${(p) => p.fontStyle && p.fontStyle};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  svg {
    font-size: ${(p) => p.svgfontSize && p.svgfontSize};
    margin-top: ${(p) => p.svgmarginTop && p.svgmarginTop};
    margin-left: ${(p) => p.svgmarginLeft && p.svgmarginLeft};
  }
  word-break: ${(p) => p.wordBreak && p.wordBreak};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  bottom: ${(p) => p.bottom && p.bottom};
  right: ${(p) => p.right && p.right};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  display: ${(p) => p.display && p.display};
  vertical-align: ${(p) => p.verticalAlign && p.verticalAlign};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};

  &.flexWrapTxt {
    @media screen and (max-width: 1071px) {
      margin-left: 13px;
    }
  }
  &.flexWrapDt {
    @media screen and (max-width: 1071px) {
      margin-left: 23px;
    }
  }
  /* @media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
  } */
`;

export const AlertDiv = styled(Div)`
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  & svg.svg-alert {
    height: 24px;
    width: 24px;
    color: red;
    margin-right: 10px;
  }

  svg.svg-close {
    height: 24px;
    width: 24px;
    margin-left: auto;
    cursor: pointer;
  }
`;
export const WenoDiv = styled(Div)`
  &.flexWrapDt {
    @media screen and (max-width: 1021px) {
      border-top: none;
      background-color: revert;
    }
  }
  border-top: 1px solid #e0e0e0;
  background-color: #f1f5f8;
`;
