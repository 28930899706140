import { createSlice } from "@reduxjs/toolkit";

export const SubsRenewalSlice = createSlice({
  name: "SubsRenewal",
  initialState: {
    AccountVerification: {},
    Accountotp: {},
    TemporaryRes: {},
    TemporaryPassword: {},
    TemporaryEmail: "",
    TemporaryOtp: "",
    ReactiveRecover: "",
    LoaderCall: "",
  },
  reducers: {
    getAccountVerification: (state) => {
      return state;
    },
    setAccountVerification: (state, action) => {
      state.AccountVerification = action.payload;
    },
    setAccountotp: (state, action) => {
      state.Accountotp = action.payload;
    },
    getTemporaryRes: (state) => {
      return state;
    },
    setTemporaryRes: (state, action) => {
      state.TemporaryRes = action.payload;
    },
    setTemporaryPassword: (state, action) => {
      state.TemporaryPassword = action.payload;
    },
    setTemporaryEmail: (state, action) => {
      state.TemporaryEmail = action.payload;
    },
    setTemporaryOtp: (state, action) => {
      state.TemporaryOtp = action.payload;
    },
    setReactiveRecover: (state, action) => {
      state.ReactiveRecover = action.payload;
    },
  },
});

export const {
  getTemporaryRes,
  getAccountVerification,
  setAccountVerification,
  setAccountotp,
  setTemporaryRes,
  setTemporaryPassword,
  setTemporaryEmail,
  setTemporaryOtp,
  setReactiveRecover,
} = SubsRenewalSlice.actions;

export default SubsRenewalSlice.reducer;
