import styled from "styled-components";

export const Button = styled.button`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  background-color: ${(p) => p.bc && p.bc};
  background: ${(p) => p.background && p.background};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.br && p.br};
  margin-right: ${(p) => p.mr && p.mr};
  color: ${(p) => p.color && p.color};

  &:hover {
    color: ${(p) => p.background && "#ffffff"};
    color: ${(p) => p.bc && p.bc};
    background-color: ${(p) => p.color && p.color};
  }
`;
