import axios from "axios";

// localStorage.setItem("DBHoast", Encrypt_Value("default", "vozo"));

var headers = {};

if (localStorage.token) {
  let DBHost;
  let finalloginurl;
  if (window.location.href.includes("localhost")) {
    DBHost = "default";
    finalloginurl = process.env.REACT_APP_BACKEND_URL;
  } else {
    let firstDot = window.location.hostname.split(".");
    if (
      firstDot[0] !== "ehr" &&
      firstDot[0] !== "ehr1" &&
      firstDot[0] !== "test" &&
      firstDot[0] !== "mumbai" &&
      firstDot[0] !== "dev" &&
      firstDot[0] !== "production"
    ) {
      DBHost = firstDot[0];
      finalloginurl = process.env.REACT_APP_BACKEND_URL.replace("oemr", DBHost);
    } else {
      DBHost = "default";
      finalloginurl = process.env.REACT_APP_BACKEND_URL.replace(
        "oemr",
        firstDot[0]
      );
    }
  }

  headers.Authorization = `${localStorage.token}`;

  var baseURL = finalloginurl + DBHost + "/api";
} else {
  let DBHost;
  let finalurl;
  if (window.location.href.includes("localhost")) {
    DBHost = "default";
    finalurl = process.env.REACT_APP_BACKEND_URL;
  } else {
    let firstDot = window.location.hostname.split(".");
    if (
      firstDot[0] !== "ehr" &&
      firstDot[0] !== "ehr1" &&
      firstDot[0] !== "test" &&
      firstDot[0] !== "mumbai" &&
      firstDot[0] !== "dev" &&
      firstDot[0] !== "production"
    ) {
      DBHost = firstDot[0];
      finalurl = process.env.REACT_APP_BACKEND_URL.replace("oemr", DBHost);
    } else {
      DBHost = "default";
      finalurl = process.env.REACT_APP_BACKEND_URL.replace("oemr", firstDot[0]);
    }
  }

  var baseURL = finalurl + DBHost;
  var headers = {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  };
}

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers,
});
axiosInstance.interceptors.request.use(
  (config) => {
    if (localStorage.token) {
      config.headers.Authorization = localStorage.token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axiosInstance;
