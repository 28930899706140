import styled from "styled-components";
import { HiOutlineAtSymbol } from "react-icons/hi";
import { GrAttachment } from "react-icons/gr";
import { AiOutlineSend } from "react-icons/ai";
import { BsXLg } from "react-icons/bs";
import { BsDownload } from "react-icons/bs";

export const DataDiv = styled.div`
  align-items: center;
  padding: ${(p) => p.padding && p.padding};
  position: ${(p) => p.position && p.position};
  border-bottom: ${(p) => p.bb && p.bb};
  margin-bottom: ${(p) => p.mb && p.mb};
  margin-left: ${(p) => p.ml && p.ml};
  margin-top: ${(p) => p.mt && p.mt};
  margin-right: ${(p) => p.mr && p.mr};
  display: ${(p) => p.display && p.display};
  cursor: ${(p) => p.cursor && p.cursor};
  overflow: ${(p) => p.overflow && p.overflow};
  background: ${(p) => p.background && p.background};
  border-radius: ${(p) => p.br && p.br};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  text-align: ${(p) => p.textAlign && p.textAlign};
  min-width: ${(p) => p.minWidth && p.minWidth};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  aspect-ratio: ${(p) => p.aspectRatio && p.aspectRatio};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
`;
export const Data = styled(DataDiv)`
  width: ${(p) => p.width && p.width};
  // max-height: 418px;
  border: ${({ drop }) => (drop ? "dotted 2px #8DA3F0" : "")};
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const FileInput = styled.input`
  display: none;
`;

export const Div = styled.div`
  display: ${(p) => p.dis && p.dis};
  align-items: ${(p) => (p.alignItems ? p.alignItems : "center")};
  position: ${(p) => p.position && p.position};
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
  min-height: ${(p) => p.minheight && p.minheight};
  max-height: ${(p) => p.maxheight && p.maxheight};
  flex-direction: ${(p) => p.flexDirection && p.flexDirection};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  padding: ${(p) => p.padding && p.padding};
  border: ${(p) => p.border && p.border};
  border-radius: ${(p) => p.radius && p.radius};
  outline: none;
  font-family: ${(p) => p.family && p.family}, sans-serif;
  font-weight: ${(p) => p.weight && p.weight};
  font-size: ${(p) => p.size && p.size};
  line-height: ${(p) => p.lineheight && p.lineheight};
  overflow-x: ${(p) => p.overflowX && p.overflowX};
  overflow: ${(p) => p.overflow && p.overflow};
`;
export const Row = styled(Div)`
  border-bottom: ${(p) => p.borderBottom && p.borderBottom};
  cursor: ${(p) => p.cursor && p.cursor};
  max-height: ${(p) => p.maxHeight && p.maxHeight};
  width: ${(p) => p.width && p.width};
  height: ${(p) => p.height && p.height};
  top: ${(p) => p.top && p.top};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  max-width: ${(p) => p.maxWidth && p.maxWidth};
  margin: ${(p) => p.margin && p.margin};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
`;
export const ChatBoxMsg = styled.div`
  background: ${({ user, day }) =>
    day ? "#ffff" : user ? "#407BFF" : "rgb(196,196,196) "};
  max-width: 391px;
  color: ${({ user, day }) =>
    day ? "#BAC0C6" : user ? " #FFFFFF" : "#000000"};
  border-radius: ${({ type }) => (type === "date" ? "4px" : "8px")};
  padding: ${({ type, padding }) =>
    padding !== undefined ? padding : type === "date" ? "7px 20px" : "4px 8px"};
  padding: ${({ type }) => type === "image" && "8px 10px 7px 10px"};

  margin-right: ${({ user }) => user && "12px"};
  display: flex;
  border: ${({ day }) => day && "none"};
  position: relative;
  min-height: 20px;

  ${({ user }) =>
    user &&
    `
&::after {
  content: " ";
  border: 12px solid transparent;
  position: absolute;
  border-left-color: #407BFF;
  border-right: 0;
  right: -6px;
  top: 26px;
  margin-top: -19px;
}
`};
  ${({ user, type }) =>
    !user &&
    type !== "date" &&
    `
&::before {
  content: " ";
  border: 12px solid transparent;
  position: absolute;
  border-right-color:  rgb(196, 196, 196);
  border-left: 0;
  left: 11px;
  top: 1px;
  margin-left: -16px;
  
}
`};
`;

export const ChatBox = styled.div`
  align-self: ${({ user, type }) =>
    type === "date" ? "center" : user ? "flex-end" : "flex-start"};
  justify-content: ${({ user, type }) =>
    type === "date" ? "center" : user ? "flex-end" : "flex-start"};
  margin-bottom: 18px;
  max-width: ${({ type }) =>
    type === "date" ? "100%" : type === "text" ? "100%" : "fit-content"};
  text-align: ${({ type }) => (type === "date" ? "center" : "left")};
  display: flex;
  flex-direction: row;
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
`;

export const Avatar = styled.div`
  border-radius: 50%;
  width: ${(p) => p.width && p.width};
  min-width: ${(p) => p.minWidth && p.minWidth};
  height: ${(p) => p.height && p.height};
  background-color: #a8aebe;
  display: inline-block;
  margin-right: 12px;
  ${({ flex }) =>
    flex &&
    ` display : flex;
  align-items: center;
  justify-content: center;`};

  border: ${(p) => p.border && p.border};
  margin: ${(p) => p.margin && p.margin};
`;

export const AvatarLetter = styled.span`
  font-size: ${(p) => (p.fontSize ? p.fontSize : "20px")};
  font-weight: 600;
  line-height: 16px;
  align-items: center;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  top: ${(p) => p.top && p.top};
  left: ${(p) => p.left && p.left};
`;

export const Image = styled.img`
  height: ${(p) => p.height && p.height};
  width: ${(p) => p.width && p.width};
  margin-right: ${(p) => p.mr && p.mr};
  margin-top: ${(p) => p.mt && p.mt};
  margin-left: ${(p) => p.ml && p.ml};
  padding-right: ${(p) => p.pr && p.pr};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  object-fit: ${(p) => p.objectFit && p.objectFit};
  max-height: ${(p) => p.MaxHeight && p.MaxHeight};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  margin: ${(p) => p.margin && p.margin};
  cursor: ${(p) => p.cursor && p.cursor};
`;

export const Text = styled.p`
  margin-bottom: 0px !important;
  align-items: center;
  letter-spacing: 0.0025em;
  text-align: ${(p) => p.textAlign && p.textAlign};
  display: ${(p) => p.display && p.display};
  position: ${(p) => p.position && p.position};
  bottom: ${(p) => p.bottom && p.bottom};
  right: ${(p) => p.right && p.right};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-size: ${(p) => p.fontSize && p.fontSize};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  color: ${(p) => p.color && p.color};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  margin-right: ${(p) => p.mr && p.mr};
  margin-top: ${(p) => p.mt && p.mt};
  padding-top: ${(p) => p.pt && p.pt};
  width: ${(p) => p.width && p.width};
  white-space: ${(p) => p.whiteSpace && p.whiteSpace};
  text-overflow: ${(p) => p.textOverflow && p.textOverflow};
  overflow: ${(p) => p.overflow && p.overflow};
  margin: ${(p) => p.margin && p.margin};
  padding: ${(p) => p.padding && p.padding};
  max-width: ${(p) => p.MaxWidth && p.MaxWidth};
  height: ${(p) => p.height && p.height};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  overflow-wrap: ${(p) => p.overflowWrap && p.overflowWrap};
  word-break: ${(p) => p.wordBreak && p.wordBreak};
`;

export const SearchInputText = styled.input.attrs({
  placeholderTextColor: "red",
})`
  background: none;
  border: none;
  box-shadow: none;
  width: ${(p) => p.width && p.width};
  font-size: 14px;
  padding-left: 5px;
  &::placeholder {
    color: rgba(46, 46, 46, 0.5);
    font-family: "Open Sans", sans-serif;
  }
  outline-offset: -26px;
  display: ${(p) => p.display && p.display};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};

  &:focus {
    outline: none;
  }
`;

export const At = styled(HiOutlineAtSymbol)`
  color: #2e2e2e;
  width: 21px !important;
  height: 23px !important;
  margin-right: 16px;
  cursor: pointer;
`;
export const Attach = styled(GrAttachment)`
  color: #2e2e2e;
  width: 20px !important;
  height: 20px !important;
  margin-right: 16px;
  cursor: pointer;
`;

export const Send = styled(AiOutlineSend)`
  color: #ffffff;
  width: 20px !important;
  height: 20px !important;
  margin-left: 3px !important;
`;

export const Button = styled.button`
  cursor: ${(p) => p.cursor && p.cursor};
  height: ${(p) => p.height && p.height};
  border-radius: ${(p) => p.borderRadius && p.borderRadius};
  box-shadow: ${(p) => p.boxShadow && p.boxShadow};
  background: ${(p) => p.background && p.background};
  background-color: ${(p) => p.backgroundColor && p.backgroundColor};
  color: ${(p) => p.color && p.color};
  border: ${(p) => p.border && p.border};
  border-color: ${(p) => p.borderColor && p.borderColor};
  padding: ${(p) => p.padding && p.padding};
  padding-left: ${(p) => p.paddingLeft && p.paddingLeft};
  padding-right: ${(p) => p.paddingRight && p.paddingRight};
  padding-top: ${(p) => p.paddingTop && p.paddingTop};
  padding-bottom: ${(p) => p.paddingBottom && p.paddingBottom};
  font-weight: ${(p) => p.fontWeight && p.fontWeight};
  font-family: ${(p) => p.fontFamily && p.fontFamily};
  margin: ${(p) => p.margin && p.margin};
  font-size: ${(p) => p.fontSize && p.fontSize};
  margin-right: ${(p) => p.marginRight && p.marginRight};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  margin-left: ${(p) => p.marginLeft && p.marginLeft};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  display: ${(p) => p.display && p.display};
  justify-content: ${(p) => p.justifyContent && p.justifyContent};
  align-items: ${(p) => p.alignItems && p.alignItems};
  line-height: ${(p) => p.lineHeight && p.lineHeight};
  width: ${(p) => p.width && p.width};
  letter-spacing: ${(p) => p.letterSpacing && p.letterSpacing};
  margin-top: ${(p) => p.marginTop && p.marginTop};
  margin-bottom: ${(p) => p.marginBottom && p.marginBottom};
  position: ${(p) => p.position && p.position};
  text-align: ${(p) => p.textAlign && p.textAlign};
  &:hover {
    background-color: ${(p) => p.hoverBackColor && p.hoverBackColor};
  }
  svg {
    font-size: ${(p) => p.svgfontSize && p.svgfontSize};
    position: ${(p) => p.svgPosition && p.svgPosition};
    top: ${(p) => p.svgTop && p.svgTop};
    left: ${(p) => p.svgLeft && p.svgLeft};
  }
  ${(p) =>
    p.disabled &&
    `
      cursor: not-allowed;
      `}
  &:hover {
    &.bgFFF {
      background-color: #f4f6f9;
    }
    &.bg-2c7be5 {
      background-color: #005fb2;
    }
  }
  &.detailupdatebtn {
    @media (max-width: 700px) {
      padding: 4px 4px 4px 4px;
      margin-left: 15px;
    }
  }
`;

export const Close = styled(BsXLg)`
  margin-left: auto;
  cursor: pointer;
  color: #407bff;
  height: 12px;
`;

export const DowloadBtn = styled(BsDownload)`
  cursor: pointer;
  color: #407bff;
  font-weight: bold;
  margin-left: 20px;
  height: 12px;
  width: 12px;
`;

export const UserContent2 = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 25.36px 8px 34px;
  // height: 56px;
  width: 100%;
  border-left: ${({ active }) => active && "4px solid #407bff"};
  // background: ${({ active }) => active && "rgba(64, 123, 255, 0.1)"};
  background: ${({ active }) =>
    active ? "rgba(64, 123, 255, 0.1)" : "#F1F5F8"};
  border-radius: 4px;
  cursor: pointer;
  // margin: 10px 0px;
  &:hover {
    background: rgba(46, 46, 46, 0.07);
  }
`;

export const StyledBlueText = styled.span`
  color: blue;
`;
