import axiosInstance from "../../axios/axios";
import { Decrypt_Value } from "../../MiddleWare/EncryptDecrypt";
//var uid = Decrypt_Value(localStorage.getItem("user_id"), "vozo");

const UID =
  localStorage.getItem("user_id") &&
  Decrypt_Value(localStorage.getItem("user_id"), "vozo");

export const ClinicalReportDetails_Api = async (getDetails) =>
  await axiosInstance.put(`/vozo/report/getclinicalreport`, getDetails);

export const ClinicalReportDetailsCount_Api = async (getDetails) =>
  await axiosInstance.get(`/vozo/report/getclinicalcount?search=` + getDetails);

export const Currency_Api = async () =>
  await axiosInstance.get(`/vozo/setting/billing/currencydata?uid=${UID}`);
