import axiosInstance from "../../axios/axios";

export const MessageSendApi = async (credential) =>
  await axiosInstance.post(`/vozo/provider/sendMessage`, credential);

export const MessageListApi = async (credential) =>
  await axiosInstance.post(`/vozo/provider/getMessagelist`, credential);

export const PreviewDownloadApiMessage = async (credential) =>
  await axiosInstance.put(`/vozo/PreviewDownloadmessenger`, credential);

export const UnreadCountUpdateApi = async (credential) =>
  await axiosInstance.put(`/vozo/unreadCountUpdate`, credential);

export const TotalUnreadCountApi = async (credential) =>
  await axiosInstance.put(`/vozo/TotalunreadCount`, credential);

export const SecureMessageListApi = async (credential) =>
  await axiosInstance.get(`/vozo/appointment/secure/Message?uid=` + credential);

export const SecureMessageSearchApi = async (credential) =>
  await axiosInstance.get(`/vozo/patientsearchportal?search=` + credential);
